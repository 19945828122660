import React, { ReactElement, useCallback, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { DoctorType } from 'shared';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import {
  Box,
  Avatar,
  useMediaQuery,
  useTheme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CustomButton from 'components/ui/Button';
import { ReactComponent as CheckSubscribeIcon } from 'assets/CheckSubscribeIcon.svg';
import Text from 'Text';
import useSubscribe from '@eggmed/graphql-client/operations/subscriptionOperations/useSubscribe';

import { yupResolver } from '@hookform/resolvers';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Menu from '../../ui/Menu';
import { useHeaderPageNameState } from '../HeaderPageNameConext';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import concatName from 'utils/userUtils';
import { ReactComponent as RightArrowIcon } from 'assets/RightArrow.svg';
import useStyles from './styles';
import { useGetMenuNotifications } from './useGetMenuNotifications';
import Badge from '@material-ui/core/Badge';
import NotificationMenu from 'components/Notification/NotificationsMenu';
import { useProvider } from 'pages/PatientNotificationPage/Provider';
import { ReactComponent as UserIcon } from 'assets/userDropDown/userIcon.svg';
import { ReactComponent as LogoutIcon } from 'assets/userDropDown/logoutIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/userDropDown/settingsIcon.svg';
import { ReactComponent as HelpIcon } from 'assets/userDropDown/help.svg';
import { ReactComponent as TermsIcon } from 'assets/userDropDown/terms.svg';
import { ReactComponent as NewEggmedLogo } from 'assets/NewEggmedLogo.svg';
import { ReactComponent as NotificationsActiveOutlinedIcon } from 'assets/NewNotif.svg';
import clsx from 'clsx';
import SearchInput from './searchInput';
import { ReactComponent as SearchIcon } from 'assets/searchIcon.svg';
import { FormProvider, useForm } from 'react-hook-form';

import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import { ReactComponent as AddPatientIcon } from 'assets/AddP.svg';
import { ReactComponent as ChangeWorkingHourIcon } from 'assets/ScheduleI.svg';
import { ReactComponent as ScheduleAppIcon } from 'assets/ScheduleP.svg';
import { useProvider as useSchedule } from 'pages/SchedulePage/state/SchedulePageProvider';
import BasicModal from 'components/ui/Modal';
import useGenerateAccount from '@eggmed/graphql-client/operations/patientOperations/useGenerateAccount';
import AddPatientModal from './AddPatientModal';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import Skeleton from 'components/ui/Skeleton';
import Button from 'components/ui/Button';
import { ReactComponent as BoostActivation } from 'assets/BoostActivation.svg';
import { ReactComponent as CloseSubscribe } from 'assets/CloseSubscribe.svg';
import ProgressLoader from 'components/ui/ProgressLoader';
import {
  CardNumberElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import AddEventPage from 'pages/SchedulePage/AddEventPage';
import useAddCheckPermission from 'contexts/AddPermissionOntext';
import { useRate } from 'pages/AdminPage/Payment/useRate';

interface IUser {
  firstname: string;
  lastname: string;
}
interface IPatient {
  user?: IUser;
  firstname: string;
  lastname: string;
  doctorId?: string;
}
export const schema = yup.object().shape({
  firstname: yup.string().required('First name is a required field'),
  lastname: yup.string().required('Last name is a required field'),
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Email address is a required field'),
});
interface Props {
  openAddAppointment?: () => void;
  doctor: DoctorType;
  authData?: IPatient;
  staffHasNoDoctor?: boolean;
}

const Header = ({
  doctor,
  authData,
  staffHasNoDoctor = false,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { isEnabled, loading: loadingAccess } =
    useAddCheckPermission('Clients');
  const { isEnabled: isEnabledToSchedule } = useAddCheckPermission('Schedule');
  const {
    back,
    handleOpenAddModal,
    handleCloseEditMode,
    handleUpdateAppointment,
    handleAddAppointment,
    modalAddOpen,
    currentEvent,
    editMode,
    loadingAddAppointment,
    loadingEditAppointment,
    handleAddEvent,
    handleUpdateEvent,
    clickableEvent,
    loadingEditEvent,
    openPatientModal,
    patientModalTitle,
    handleOpenPatientModal,
    handleClosePatientModal,
  } = useSchedule();
  const {
    staff,
    handleChangeSelectDoctor,
    user,
    loadingSubscription,
    dataSubscription,
    setOpen,
    open,
    loading,
  } = useAuth();

  const { roles } = useRate('', '', false);

  const { generateAccount, loading: generateAccountLoading } =
    useGenerateAccount();
  const methodsContact = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text_about_upcoming: true,
      text_about_canceled: true,
      email_about_canceled: true,
      email_about_upcoming: true,
      selectedTemplates: dataSubscription?.doctorAutoSendTemplates.map(
        (el) => el._id
      ),
      sendIntake: true,
      assignedRoles: roles?.ownerRoles?.map((el) => {
        return { ...el, name: el.name, id: el?._id };
      }),
      assignedProviders: [
        {
          ...doctor,
          name: `${doctor?.firstname} ${doctor?.lastname}`,
          id: doctor?._id,
          doctorData: {
            _id: doctor?._id,
          },
        },
      ],
    },
  });
  React.useEffect(() => {
    if (dataSubscription) {
      methodsContact.reset({
        text_about_upcoming: true,
        text_about_canceled: true,
        email_about_canceled: true,
        email_about_upcoming: true,
        selectedTemplates: dataSubscription?.doctorAutoSendTemplates.map(
          (el) => el._id
        ),
        sendIntake: true,
        assignedRoles: roles?.ownerRoles?.map((el) => {
          return { ...el, name: el.name, id: el?._id };
        }),
        assignedProviders: [
          {
            ...doctor,
            name: `${doctor?.firstname} ${doctor?.lastname}`,
            id: doctor?._id,
            doctorData: {
              _id: doctor?._id,
            },
          },
        ],
      });
    }
  }, [dataSubscription, methodsContact.reset, roles?.ownerRoles, doctor]);
  const { register, control, errors, watch, setValue, setError } =
    methodsContact;
  const commonProps = { register, control, errors, watch, setValue, setError };

  const isTrialing =
    dataSubscription?.subscription?.status === 'trialing' &&
    !loadingSubscription;
  const subcriptionType = [
    { name: 'Annual billing' },
    { name: 'Monthly billing' },
  ];
  const [activeTab, setActiveTab] = React.useState(0);

  const products = dataSubscription?.getEggmedProduct;

  const getProductDependOnInterval = (interval) => {
    return (
      products?.length > 0 && products?.find((el) => el.interval === interval)
    );
  };
  const billingInfo = {
    0: {
      label: 'Annualy',
      description: `Billed annualy at $${
        getProductDependOnInterval('year')?.unit_amount / 100
      } (USD)`,
      value: getProductDependOnInterval('year')?.unit_amount / 100,
      interval: getProductDependOnInterval('year')?.interval,
      valueMonth: getProductDependOnInterval('year')?.unit_amount / 1200,
      priceId: getProductDependOnInterval('year')?.priceId,
    },
    1: {
      label: 'Monthly',
      description: 'Billed monthly (USD)',
      value: getProductDependOnInterval('month')?.unit_amount / 100,
      interval: getProductDependOnInterval('month')?.interval,
      priceId: getProductDependOnInterval('month')?.priceId,
    },
  };

  function calculateSave(monthly, yearly) {
    return yearly * 12 - monthly * 12;
  }
  const features = [
    'Unlimited clients',
    'Custom note templates',
    'Eggmed mobile app',
    'Online payment',
    'Secure client management',
    'Digital client intake',
    'Telehealth',
    '+ many more...',
    'Custom assessment form templates',
  ];
  const { subscribeEggmed, loading: loadingSubscribe } = useSubscribe();

  async function handleChoosePlan() {
    const result = await subscribeEggmed({
      variables: {
        subscribeInput: {
          priceId: billingInfo[activeTab]?.priceId,
          interval: billingInfo[activeTab]?.interval,
        },
      },
    });
    window.location.href = result?.data?.subscribeEggmed;
  }

  const { title } = useHeaderPageNameState();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [values, setValues] = React.useState<any>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [notificationMenuAnchor, setNotificationMenuAnchor] =
    React.useState<null | HTMLElement>(null);
  const [switchAccountAnchor, setSwitchAccountAnchor] =
    React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenNotifMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleCloseSwitchAccount();
    setNotificationMenuAnchor(null);
  };
  const handleOpenSwitchAccount = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSwitchAccountAnchor(event.currentTarget);
  };
  const handleCloseSwitchAccount = () => {
    setSwitchAccountAnchor(null);
  };
  const { start, limit } = useProvider();
  const { menuNotifElements, count, unreadNotifications } =
    useGetMenuNotifications(user._id, { start, limit });
  const { triggerSnack } = useSnackbar();
  const MenuElementsData = [
    {
      name: t('Profile'),
      avatar: <UserIcon />,
      onClick: () => {
        history.push(`/doctor/${authData?.doctorId}`);
      },
      route: `/doctor/${authData?.doctorId}`,
    },
    {
      name: t('Settings'),
      avatar: <SettingsIcon />,
      onClick: () => {
        history.push(`/doctor/admin`);
      },
      route: `/doctor/admin`,
    },
    {
      name: 'Switch',
      accessoryRight: <RightArrowIcon />,
      onClick: handleOpenSwitchAccount,
      permission: ['none'],
    },
    {
      name: t('Help center'),
      avatar: <HelpIcon />,
      onClick: () => history.push('help'),
      route: 'help',
    },
    {
      name: t('Terms and conditions'),
      avatar: <TermsIcon />,
      onClick: () => history.push('terms'),
      route: 'terms',
    },
    {
      name: t('Log out'),
      avatar: <LogoutIcon />,
      onClick: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('doctor');
        window.location.reload();
      },
    },
  ];
  const MenuElementByRole = () =>
    MenuElementsData?.filter(
      (element) =>
        !element?.permission || element?.permission.includes(user?.role)
    );
  const staffMenu = MenuElementsData?.slice(-1);
  const MenuSwitchDoctor = useCallback((staffOf) => {
    let doctorMenu = [];
    staffOf?.map((doctorStaff) => {
      const { _id, firstname, lastname, middlename, picture } = doctorStaff;
      const name = concatName(firstname, lastname, middlename);
      doctorMenu.push({
        name,
        selected: doctor._id === _id,
        accessoryLeft: (
          <Avatar
            className={classes.switchAccountAvatar}
            alt={name}
            src={picture}
          />
        ),
        onClick: () => handleChangeSelectDoctor(_id),
      });
    });
    return doctorMenu;
  }, []);

  // const [error, setError] = React.useState('');
  const stripe = useStripe();
  const elements = useElements();
  async function onAddPatient(field) {
    let paymentMethodToken = undefined;
    if (field?.payment) {
      const cardElement = elements.getElement(CardNumberElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: `${user?.firstname} ${user?.lastname}`,
        },
      });
      paymentMethodToken = paymentMethod.id;
    }

    const { sendIntake, payment, country, selectedTemplates, ...rest } = field;
    const username = `${rest.firstname} ${rest.lastname}`;
    try {
      await generateAccount({
        variables: {
          userInput: {
            ...rest,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            username,
            isAdult: checked,
            paymentMethodToken,
            selectedTemplates: sendIntake ? selectedTemplates : undefined,
            assignedRoles: field?.assignedRoles?.map((el) => el?._id),
            assignedProviders: field?.assignedProviders?.map(
              (el) => el?.doctorData?._id
            ),
          },
        },
      });
      triggerSnack();
      handleClosePatientModal();
      // setError('');
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: error?.message,
      });
    }
  }
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = useCallback(
    (anchor: string, open: boolean) => (event: any) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    },
    [state]
  );
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true,
  });

  const [checked, setChecked] = React.useState(true);
  function handleChange(value) {
    setChecked(value);
  }
  const quickAction = [
    {
      icon: <AddPatientIcon />,
      title: t('Add client'),
      click: isEnabled
        ? () => {
            handleOpenPatientModal();
          }
        : undefined,
    },
    {
      icon: <ChangeWorkingHourIcon />,
      title: t('Schedule session'),
      click: isEnabledToSchedule
        ? () => {
            handleOpenAddModal();
          }
        : undefined,
    },
    {
      icon: <ScheduleAppIcon />,
      title: t('Change working hours'),
      click: () => {
        history.push('/doctor/admin?query=workingHours');
      },
    },
  ];
  return (
    <Box display="flex" position="relative" className={classes.root}>
      <Box display="flex" alignItems="center">
        <NewEggmedLogo
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/doctor')}
        />
        <Box display="flex" alignItems="center" ml={7} mr={2}>
          <SearchInput
            icon={<SearchIcon />}
            noOptionsText="No matches"
            options={convertObjectsFormat(
              dataSubscription?.patientsRelated,
              ''
            )}
            placeholder="Search"
            values={values}
            setValues={setValues}
            searchValue={searchValue}
            filterOptions={(options, { inputValue }) => {
              const inputValueLowerCase = inputValue.toLowerCase();
              setSearchValue(inputValueLowerCase);
              return options.filter(
                (option) =>
                  option.email.toLowerCase().includes(inputValueLowerCase) ||
                  option.name.toLowerCase().includes(inputValueLowerCase)
              );
            }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          {quickAction?.map((el, index) => (
            <Tooltip title={el.title}>
              <Box
                style={{
                  cursor: 'pointer',
                  width: 40,
                  height: 40,
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  opacity:
                    (!isEnabledToSchedule && index === 1) ||
                    (!isEnabled && index === 0)
                      ? 0.4
                      : 1,
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr={1}
                onClick={el.click}
              >
                {el.icon}
              </Box>
            </Tooltip>
          ))}
        </Box>
      </Box>

      <Box className={classes.rightHeader}>
        {isTrialing && (
          <Button className={classes.activateBtn} onClick={() => setOpen(true)}>
            <BoostActivation style={{ marginRight: '5px' }} />
            Activate your plan
          </Button>
        )}
        {/* {upcomingAppointment && (
          <div
            onClick={() =>
              history.push(
                `/doctor/patient/${upcomingAppointment?.patient._id}?event=${upcomingAppointment?._id}&tab=2`
              )
            }
          >
            <MeetingCard
              avatarData={upcomingAppointment.patient?.picture}
              patientName={
                upcomingAppointment.patient?.firstname +
                ' ' +
                upcomingAppointment.patient?.lastname
              }
              className={classes.displayMeetingCard}
              appointment={upcomingAppointment}
            />{' '}
          </div>
        )} */}
        {/* {inProgressAppointment && (
          <div
            onClick={() =>
              history.push(
                `/doctor/patient/${inProgressAppointment?.patient._id}?event=${inProgressAppointment?._id}&tab=2`
              )
            }
          >
            <MeetingCard
              avatarData={inProgressAppointment.patient?.picture}
              patientName={
                inProgressAppointment.patient?.firstname +
                ' ' +
                inProgressAppointment.patient?.lastname
              }
              inProgress
              className={classes.hoverElement}
              appointment={inProgressAppointment}
            />
          </div>
        )} */}

        {count > 0 ? (
          <Box onClick={handleOpenNotifMenu} style={{ marginInline: '15px' }}>
            <Badge
              badgeContent={count < 9 ? count : '9+'}
              color="error"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <NotificationsActiveOutlinedIcon
                className={classes.iconsNotif}
                color="primary"
              />
            </Badge>
          </Box>
        ) : (
          <Box onClick={handleOpenNotifMenu} style={{ marginInline: '15px' }}>
            <NotificationsActiveOutlinedIcon
              className={classes.iconsNotif}
              color="primary"
            />
          </Box>
        )}

        <SettingsOutlinedIcon
          className={classes.iconsSetting}
          color="primary"
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleClick}
        >
          <Skeleton
            loading={loading}
            type="circle"
            width="43px"
            height="43px"
            className={classes.marginSkeleton}
          >
            <Avatar
              src={doctor?.picture}
              className={clsx(
                classes.icons,
                classes.profilePic,
                anchorEl && classes.picOutline
              )}
            >
              {` ${doctor && doctor?.firstname[0].toUpperCase()}${
                doctor && doctor?.lastname[0].toUpperCase()
              } `}
            </Avatar>
          </Skeleton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          handleMenuClose={handleMenuClose}
          MenuElements={staffHasNoDoctor ? staffMenu : MenuElementByRole()}
          className={classes.menu}
        />
        <Menu
          anchorEl={switchAccountAnchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!switchAccountAnchor}
          handleMenuClose={handleMenuClose}
          MenuElements={MenuSwitchDoctor(staff?.staffOf)}
          className={classes.menu}
        />
        <NotificationMenu
          anchorEl={notificationMenuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!notificationMenuAnchor}
          handleMenuClose={handleMenuClose}
          menuElements={menuNotifElements}
          unreadNotifications={unreadNotifications}
        />
      </Box>

      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        isSlide
        direction="up"
      >
        <Box
          style={{ backgroundColor: '#F9F8F7' }}
          width="100vw"
          height="100vh"
        >
          <Box
            pl="8rem"
            pr={'4rem'}
            py={'2rem'}
            display="flex"
            justifyContent="space-between"
            style={{
              background: 'linear-gradient(45deg, #39A0FF 0%, #8FFF85 100%)',
            }}
          >
            <Box>
              {/* <EggmedWhitelg /> */}
              <Box>
                <Typography className={classes.headerLabel}>
                  Select Your Eggmed Subscription Plan{' '}
                </Typography>
                <Typography className={classes.headerDesc}>
                  Eggmed offers a single, comprehensive plan that includes all
                  the features necessary for seamless in-office and virtual
                  care.
                </Typography>
              </Box>
            </Box>
            <CloseSubscribe
              onClick={() => setOpen(false)}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            overflow="scroll"
            // mt={6}
            className={classes.boxxx}
          >
            <Box
              className={classes.billingType}
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              px={0.5}
            >
              {subcriptionType.map((el, index) => (
                <Box
                  style={{
                    backgroundColor:
                      activeTab === index ? 'white' : 'transparent',
                    boxShadow:
                      activeTab === index &&
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                    borderRadius: activeTab === index && '6px',
                    cursor: 'pointer',
                  }}
                  py={1.5}
                  px={2}
                  onClick={() => setActiveTab(index)}
                >
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: 600,
                      fontSize: '16px',
                      color: activeTab === index ? '#147AF3' : '#59A7F6',
                    }}
                  >
                    {el.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box
              mt={6}
              px={4}
              pb={2}
              className={classes.billingCard}
              width="592px"
              position="relative"
            >
              {activeTab === 0 && (
                <Box
                  position="absolute"
                  left="38%"
                  top="-1rem"
                  className={classes.saveBox}
                  px={2}
                >
                  <Typography className={classes.savesInfo}>
                    Save $
                    {calculateSave(
                      billingInfo[0]?.valueMonth,
                      billingInfo[1]?.value
                    )}
                  </Typography>
                </Box>
              )}

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={'1px solid #EAECF0'}
              >
                <Box>
                  <Typography className={classes.billingName}>
                    {billingInfo[activeTab]?.label}
                  </Typography>
                  <Typography className={classes.billingDs}>
                    {billingInfo[activeTab]?.description}
                  </Typography>
                </Box>
                <Box className={classes.pricing}>
                  <Skeleton loading={loadingSubscription}>
                    <Typography className={classes.currency}>$</Typography>
                  </Skeleton>
                  <Skeleton loading={loadingSubscription}>
                    <Typography className={classes.price}>
                      {billingInfo[activeTab]?.valueMonth ||
                        billingInfo[activeTab]?.value}
                    </Typography>
                  </Skeleton>
                  <Typography className={classes.priceLabel}>
                    per month
                  </Typography>
                </Box>
              </Box>

              <Box py={3} borderBottom="1px solid #EAECF0">
                <CustomButton
                  className={classes.selectPlanBtn}
                  buttonType="reset"
                  onClick={() => handleChoosePlan()}
                >
                  {loadingSubscribe ? (
                    <ProgressLoader />
                  ) : (
                    <Text i18nKey="selectPlan" style={{ fontWeight: 500 }}>
                      Continue
                    </Text>
                  )}
                </CustomButton>
              </Box>

              <Box mt={3} pb={3}>
                <Typography className={classes.features}>Features</Typography>
                <Box mt={4} display="flex" flexWrap="wrap">
                  {features?.map((element) => (
                    <Box display="flex" mb={2} width="50%">
                      <CheckSubscribeIcon />
                      <Typography className={classes.featureName}>
                        {element}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BasicModal>
      <FormProvider {...methodsContact}>
        <BasicModal
          open={openPatientModal}
          onClose={handleClosePatientModal}
          handleClose={handleClosePatientModal}
          isFromModal
          isSlide
          divider
          title={patientModalTitle}
          titlePadding="2rem"
          onSubmit={onAddPatient}
          loading={generateAccountLoading}
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '50vw',
                xl: '37vw',
              },
            }}
          >
            <AddPatientModal
              checked={checked}
              handleChange={handleChange}
              commonProps={commonProps}
              roles={roles}
              open={openPatientModal}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <AddEventPage
        closeModal={handleCloseEditMode}
        modalAddOpen={modalAddOpen}
        editMode={editMode}
        doctorId={doctor?._id}
        eventData={currentEvent}
        addEvent={editMode ? handleUpdateAppointment : handleAddAppointment}
        handleSubmitEvent={editMode ? handleUpdateEvent : handleAddEvent}
        loadingAddAppointment={loadingAddAppointment}
        loadingEditAppointment={loadingEditAppointment}
        loadingEditEvent={loadingEditEvent}
        clickableEvent={clickableEvent}
        isBack={back}
      />
    </Box>
  );
};

Header.fragments = {
  HeaderItems: gql`
    fragment HeaderItems on User {
      firstname
      lastname
      doctorId
    }
  `,
};
export default Header;
