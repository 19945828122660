import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import FeatureNotUsed from 'components/FeatureNotUsed';
import BasicModal from 'components/ui/Modal';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ReactComponent as EmptyTasks } from 'assets/emptyState/emptyTasks.svg';
import { ITaskGlobalPage } from './index';
import List from './List';
import { useTheme } from '@material-ui/styles';
import SelectMenu from './SelectMenu';
import { useProvider } from './state/Provider';
import TaskAdd from './TaskAdd';
import TaskView from './TaskView';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import DataLoader from 'components/ui/DataLoader';
import TaskPreview from './TaskPreview';
import Skeleton from 'components/ui/Skeleton';
import { ReactComponent as EmptyTask } from 'assets/emptyState/task.svg';
import bgLines from 'assets/dashboard/background-lines.png';
import { useSnackbar } from 'hooks/useSnackbar';
import EmptyState from 'pages/DashboardPage/EmptyState';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';
import { convertObjectsFormatStaffs } from 'pages/SchedulePage/AddEventPage/utils';
import useAddCheckPermission from 'contexts/AddPermissionOntext';

declare module '@material-ui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
export enum TASK_STATUS {
  completed = 'completed',
  pending = 'pending',
  overdue = 'overdue',
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'black',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  tasksGrid: { padding: '0 !important', maxWidth: 'unset' },
  inner: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr) );',
    gap: '2rem',
    width: '100%',
    height: '90%',
    margin: '1rem 0 0 0',
  },
  innerDashboard: {
    margin: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(330px, 1fr) );',
    gap: '2rem',
    width: '100%',
  },
  list: {
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.only('sm')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  addTask: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    borderRadius: '8px',
    textTransform: 'none',
    paddingInline: '15px',
    height: '44px',
    border: '1px solid #147AF3',
    backgroundColor: '#147AF3',
    '&:hover': {
      backgroundColor: '#147AF3',
    },
  },
  stateText: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '150%',
    color: '#425364',
    marginTop: '30px',
  },
  textDetails: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '16px',
    color: '#464665',
    textTransform: 'none',
    position: 'relative',
    marginTop: '10px',
  },
  listDashboard: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  tasks: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    fill: 'solid',
    color: 'rgb(16, 24, 40)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    whiteSpace: 'nowrap',
    [theme.breakpoints.only('lg')]: {
      width: 'auto',
      flexDirection: 'column',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  addTaskContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: theme.spacing(2),
  },
  addTaskContainerDashboard: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: theme.spacing(2),
  },
  emptyTask: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',

    '& > svg': {
      zIndex: 3,
    },
  },
  bgImg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  emptyTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    color: '#101828',
    marginTop: theme.spacing(1),
    zIndex: 3,
  },
  emptySecTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
    textAlign: 'center',
    marginTop: '8px',
    marginBottom: theme.spacing(2),
    zIndex: 3,
  },
  taskBtn: {
    fontFamily: 'Inter, sans-serif',
    textTransform: 'unset',
    backgroundColor: '#0265DC',
    color: '#fff',
    borderRadius: '8px',
    paddingInline: '16px',

    '&:hover': {
      backgroundColor: '#082CAF',
      color: '#fff',
    },
  },
  text: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontWeight: 600,
  },
  icon: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
  },
}));

export const TASKS_QUERY = gql`
  query getTasks($userId: ID!) {
    tasks(userId: $userId) {
      _id
      title
      description
      status
      dueDate
      priority
      attachments {
        fileName
        createdAt
        url
        fileType
        user {
          firstname
          middlename
          lastname
          picture
          role
          _id
        }
      }
      assignedTo {
        picture
        firstname
        middlename
        lastname
        _id
      }
      createdAt
      updatedAt
    }
  }
`;
export const TASKS_LIST_PATIENT_QUERY = gql`
  query getTasksListPatient($patient: ID) {
    tasksListPatient(patient: $patient) {
      _id
      name
      tasks {
        _id
        title
        description
        status
        dueDate
        priority
        assignedTo {
          picture
          firstname
          middlename
          lastname
          _id
        }
        patients {
          picture
          firstname
          middlename
          lastname
          _id
        }
        attachments {
          fileName
          createdAt
          url
          fileType
          user {
            firstname
            middlename
            lastname
            picture
            role
            _id
          }
        }
      }
    }
  }
`;

export const TASKS_LIST_QUERY = gql`
  query getTasksList($doctors: [ID]) {
    tasksList(doctors: $doctors) {
      _id
      name
      tasks {
        _id
        title
        description
        status
        dueDate
        priority
        assignedTo {
          picture
          firstname
          middlename
          lastname
          _id
        }
        patients {
          picture
          firstname
          middlename
          lastname
          _id
        }
        attachments {
          fileName
          createdAt
          url
          fileType
          user {
            firstname
            middlename
            lastname
            picture
            role
            _id
          }
        }
      }
    }
  }
`;

const TaskPage = ({ isDashboard, data }: ITaskGlobalPage): ReactElement => {
  const {
    modalType,
    closeModal,
    modalIsOpen,
    loadingList,
    handleOpenAddTaskModal,
    errorList,
    selectedTask,
    handleAddTask,
    handleDeleteTask,
    loadingTask,
    loadingEditTask,
    dataList,
    handleEditTaskList,
    handleOpenEditTask,
    handleOpenDeleteModal,
    handleDeleteTaskAttachment,
    isAppt,
    loadingDeleteTask,
    values,
    setValues,
    allList
  } = useProvider();
  const { isEnabled, loading: loadingAccess } = useAddCheckPermission('Tasks');
  const { user } = useAuth();
  // const [allList, setAllList] = React.useState({ tasksList: [] });
  // const [loadTasks] = useLazyQuery(TASKS_LIST_QUERY);
  // React.useEffect(() => {
  //   loadTasks().then((data) => {
  //     setAllList(data.data);
  //   });
  // }, []);

  const taskList = isAppt ? dataList?.tasksListPatient : dataList?.tasksList;
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Tasks' });
  }, [headerTitleDispatcher]);
  // TODO: no used feature useEffect call.
  const { triggerSnack } = useSnackbar();
  const tasksPage = useMemo(
    () =>
      isDashboard
        ? taskList?.filter((el) => el.name !== TASK_STATUS.completed)
        : taskList,
    [taskList, isDashboard]
  );

  const classes = useStyles({ isDashboard: !!isDashboard });
  const [firstTimeModal, setFirstTimeModal] = useState(true);
  const [menuName, setMenuName] = useState('Priority');
  const [menuValue, setMenuValue] = useState('');

  const [taskToDelete, setTaskToDelete] = React.useState<string>(null);
  const { doctor } = useAuth();
  const { noUsedFeatures } = doctor;
  const [loadTeams, { data: datas, loading }] = useLazyQuery(TEAM_MEMBERS);
  React.useEffect(() => {
    if (!isDashboard)
      loadTeams({
        variables: { role: '', status: '' },
      });
  }, [loadTeams, isDashboard]);
  const users = convertObjectsFormatStaffs(datas?.teamSettings, '');
  function getList(id: string, data) {
    const getList = data?.find((el) => {
      return el?.tasks?.map((tasks) => tasks._id).includes(id);
    });
    return getList;
  }

  function changeTaskPositionSource(listId, data, fromIndex, toIndex) {
    const currentListTasks = data?.find((el) => el._id === listId)?.tasks;
    const newArray = [...currentListTasks];
    const removedElement = newArray?.splice(fromIndex, 1)[0];
    newArray?.splice(toIndex, 0, removedElement);
    const TasksIds = newArray?.map((el) => el?._id);
    handleEditTaskList([listId], [{ tasks: TasksIds, fullTasks: newArray }]);
  }
  function changeTaskPosition(
    destinationIndex: number,
    sourceListId: string,
    destinationListId: string,
    data: any[],
    draggeableId: string
  ) {
    const sourceArray = data?.find((el) => el._id === sourceListId)?.tasks;
    const destinationArray = data?.find((el) => el._id === destinationListId);
    const taskToMove = sourceArray.find((el) => el._id === draggeableId);
    const filteredArray = sourceArray.filter((el) => el._id !== draggeableId);
    const sourceTask = filteredArray.map((el) => el._id);
    const newArray = [...destinationArray?.tasks];
    newArray.splice(destinationIndex, 0, taskToMove);
    const destinationTask = newArray.map((el) => el._id);
    const newDestinationTask = destinationTask.filter(
      (item, index) => destinationTask.indexOf(item) === index
    );
    const allListTasks = allList?.tasksList
      ?.find((el) => el._id === destinationListId)
      ?.tasks?.map((el) => el._id);

    const tasksDropped = [...newDestinationTask, ...allListTasks];
    handleEditTaskList(
      [sourceListId, destinationListId],
      [
        {
          tasks: sourceTask,
          fullTasks: filteredArray,
        },
        { tasks: tasksDropped, fullTasks: newArray },
      ]
    );
  }
  enum ModalType {
    EDIT_TASK = 'EDIT_TASK',
    ADD_TASK = 'ADD_TASK',
    VIEW_TASK = 'VIEW_TASK',
    DELETE_TASK = 'DELETE_TASK',
  }

  const handleDragEnd = async ({
    source,
    destination,
    draggableId,
  }: DropResult): Promise<void> => {
    try {
      // Dropped outside the list

      if (!destination) {
        return;
      }

      // Card has not been moved
      if (
        source?.droppableId === destination?.droppableId &&
        source?.index === destination?.index
      ) {
        return;
      }

      if (source?.droppableId === destination?.droppableId) {
        changeTaskPositionSource(
          source?.droppableId,
          taskList,
          source.index,
          destination.index
        );
        triggerSnack('Task status updated');
      } else {
        changeTaskPosition(
          destination.index,
          source?.droppableId,
          destination?.droppableId,
          taskList,
          draggableId
        );
        triggerSnack('Task status updated');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getFilteredList = useMemo(() => {
    const filteredTasks = taskList?.map((el) => {
      const card = el?.tasks?.filter((card) => card?.priority === menuValue);
      return { ...el, tasks: card };
    });

    return isDashboard
      ? filteredTasks?.filter((list) => list?.name !== TASK_STATUS.completed)
      : filteredTasks;
  }, [taskList, menuValue]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });

  const areTasksEmpty = (tasklist) => {
    if (!tasklist || tasklist.length === 0) {
      return false;
    }

    const totalTasksLength = tasklist.reduce(
      (accumulator, currentObject) =>
        currentObject && currentObject.tasks
          ? accumulator + currentObject.tasks.length
          : accumulator,
      0
    );

    return totalTasksLength === 0;
  };

  if (loadingList)
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        style={{ gap: '40px' }}
        mt={8}
      >
        {[1, 2, 3]?.map(() => (
          <Skeleton loading={loadingList} width="100%" height={500} type="rect">
            <Box />
          </Skeleton>
        ))}
      </Box>
    );
  return (
    <DataLoader loading={loadingList} error={errorList} data={taskList}>
      <Box
        style={{
          backgroundColor: 'white',
        }}
        p={2}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <>
            <Box display="flex">
              <Text i18nKey="myTasks" className={classes.tasks}>
                My tasks
              </Text>

              <Box
                className={
                  isDashboard
                    ? classes.addTaskContainerDashboard
                    : classes.addTaskContainer
                }
              >
                {['Admin', 'owner']?.includes(user?.role) &&
                  users?.length > 0 && (
                    <Box ml={1} width="350px" mr={2}>
                      <Symptoms
                        img={true}
                        type=""
                        disableCustomFilter={true}
                        options={users}
                        values={values}
                        setValues={setValues}
                        hasErrors={false}
                        placeholder="Select providers"
                        multiple={false}
                        patient
                      />
                    </Box>
                  )}
                {taskList?.length !== 0 && (
                  <SelectMenu
                    menuName={menuName}
                    items={['High', 'Medium', 'Low']}
                    menuDefault="Priority"
                    setMenuName={setMenuName}
                    setMenuValue={setMenuValue}
                  />
                )}
                <Button
                  className={classes.addTask}
                  onClick={handleOpenAddTaskModal}
                  disabled={!isEnabled}
                  style={{ opacity: !isEnabled ? 0.4 : 1 }}
                >
                  <AddTaskIcon className={classes.icon} />
                  <Text className={classes.text} i18nKey="addTask">
                    {t('Add task')}
                  </Text>
                </Button>
              </Box>
            </Box>
            <Box>
              {((taskList?.length == 0 && !loadingList) ||
                areTasksEmpty(taskList)) && (
                <Box
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  height="60vh"
                  flexDirection="column"
                  alignItems="center"
                >
                  <EmptyState
                    icon={<EmptyTask />}
                    title={t('No tasks on your plate right now')}
                    text={t('to add task.')}
                    onClick={() => {
                      if (isEnabled) {
                        handleOpenAddTaskModal();
                      }
                    }}
                    isClickLeft
                    isText
                  />
                </Box>
              )}
            </Box>
          </>

          <Grid
            container
            className={
              isDashboard ? `${classes.innerDashboard}` : `${classes.inner}`
            }
            spacing={isMobile ? 2 : 6}
            style={{ paddingTop: '0px' }}
          >
            {menuValue !== '' && !areTasksEmpty(taskList)
              ? getFilteredList?.map((list: any, index: number) => (
                  <Grid
                    item
                    lg={isDashboard ? 6 : 4}
                    xl={isDashboard ? 6 : 4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={list.id}
                    className={classes.tasksGrid}
                  >
                    <List
                      className={
                        isDashboard
                          ? `${classes.listDashboard}`
                          : `${classes.list}`
                      }
                      data-cy="kanban-list"
                      handleOpenAddTaskModal={() => {
                        if (isEnabled) {
                          handleOpenAddTaskModal();
                        }
                      }}
                      list={list}
                      key={list.id}
                      listId={list.id}
                      isDashboard={isDashboard}
                      loading={loadingList}
                    />
                  </Grid>
                ))
              : !areTasksEmpty(taskList) &&
                tasksPage?.map((list: any, index: number) => (
                  <Grid
                    item
                    lg={isDashboard ? 6 : 4}
                    xl={isDashboard ? 6 : 4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={list.id}
                    className={classes.tasksGrid}
                  >
                    <List
                      className={
                        isDashboard
                          ? `${classes.listDashboard}`
                          : `${classes.list}`
                      }
                      data-cy="kanban-list"
                      handleOpenAddTaskModal={() => {
                        if (isEnabled) {
                          handleOpenAddTaskModal();
                        }
                      }}
                      list={list}
                      key={list.id}
                      listId={list.id}
                      isDashboard={isDashboard}
                      loading={loadingList}
                    />
                  </Grid>
                ))}
          </Grid>
        </DragDropContext>
        <BasicModal
          onClose={closeModal}
          open={modalIsOpen}
          isRegularModal={modalType != ModalType.VIEW_TASK && true}
          title={
            modalType === ModalType.EDIT_TASK
              ? t('Edit Task')
              : modalType === ModalType.ADD_TASK && t('Add Task')
          }
          titlePadding={modalType != ModalType.VIEW_TASK ? '2rem' : '0rem'}
          handleClose={closeModal}
          isSlide={
            modalType === ModalType.ADD_TASK ||
            modalType === ModalType.EDIT_TASK ||
            modalType === ModalType.VIEW_TASK
          }
          divider
        >
          <>
            {modalType === ModalType.EDIT_TASK && (
              <TaskView
                closeModal={closeModal}
                bgcolor="white"
                data-cy="task-view-modal"
                changeTaskPosition={changeTaskPosition}
                getList={getList}
                taskList={taskList}
                {...selectedTask!}
              />
            )}
            {modalType === ModalType.ADD_TASK && (
              <TaskAdd
                handleAddTask={handleAddTask}
                closeModal={closeModal}
                data={data}
                bgcolor="#F8FAFB"
                data-cy="task-add-modal"
                loadingTask={loadingTask}
                taskList={taskList}
              />
            )}
            {modalType === ModalType.VIEW_TASK && (
              <TaskPreview
                closeModal={closeModal}
                data-cy="task-view-modal"
                openEditModal={handleOpenEditTask}
                changeTaskPosition={changeTaskPosition}
                handleDelete={handleOpenDeleteModal}
                getList={getList}
                setSelectedTask={setTaskToDelete}
                handleDeleteTaskAttachment={handleDeleteTaskAttachment}
                {...selectedTask!}
                taskList={taskList}
              />
            )}
            {modalType === ModalType.DELETE_TASK && (
              <DeleteModalContent
                text={t('task')}
                handleClose={closeModal}
                handleDelete={() => {
                  handleDeleteTask(taskToDelete, closeModal);
                }}
                loading={loadingDeleteTask}
              />
            )}
          </>
        </BasicModal>
        {/* {noUsedFeatures.indexOf('task') !== -1 && !isDashboard && (
          <BasicModal open={firstTimeModal}>
            <FeatureNotUsed
              onClose={() => setFirstTimeModal(false)}
              type="task"
            />
          </BasicModal>
        )} */}
      </Box>
    </DataLoader>
  );
};
interface IListProps {
  cards: [string];
}

export default TaskPage;
