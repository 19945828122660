import React, { ReactElement, useEffect, useState } from 'react';
import { gql, useQuery, useMutation, ApolloError } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { log } from 'config/log';
import FileDocument from './PatientReports';
import {
  GET_REPORTS,
  ADD_REPORT,
  GET_DOCTOR_ID,
  DELETE_REPORT,
  GET_PATIENT,
} from 'pages/PatientProfilePage';
import { changeRes, transformObjectTimeZone } from 'utils';
import AppointmentPageProvider from 'pages/AppointmentPage/state/Provider';
import AppointmentListPageProvider from 'pages/AppointmentListPage/state/Provider';
import GeneralInfo from './GeneralInfo';
import EditGenralInfo from 'pages/PatientGeneralInfo/EditGenralInfo';
import useGetAppointmentsByPatient from '@eggmed/graphql-client/operations/appointmentsOperations/useGetAppointmentByPatient';
import DataLoader from 'components/ui/DataLoader';

import { UPCOMING_APPOINTMENTS_QUERY } from 'pages/PatientConsultations';
import { Feed } from './Feed';
import { ReactComponent as UpcomingIcon } from 'assets/patient-profile/upcoming.svg';
import Accordion from 'components/ui/Accordion';
import useStyles from './styles';
import BasicModal from 'components/ui/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import ContactModal from './ContactModal';
import DemographicsModal from './DemographicsModal';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import useEditPatient from '@eggmed/graphql-client/operations/patientOperations/useEditPatient';
import { useToast } from 'hooks/useToast';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import ApptCard from 'pages/PatientConsultations/ApptCardConsultation';
import useAddCheckPermission from 'contexts/AddPermissionOntext';

export interface IgeneralProps {
  generalInformation?: boolean;
  isPatient: boolean;
  patientId: string;
  data?: any;
  errorPatient?: ApolloError;
  loadingPatient?: boolean;
}
export default function PatientGeneralInformations({
  generalInformation = false,
  isPatient,
  patientId,
  data: PatientData,
  errorPatient: error,
  loadingPatient: loading,
}: IgeneralProps): ReactElement {
  const { t } = useTranslation();
  const { isEnabled } = useAddCheckPermission('Schedule');
  const { updatePatient, loading: loadingPatient } = useEditPatient();
  const { triggerToast } = useToast();
  const { handleOpenAddModal, setPatientAdded } = useProvider();
  const classes = useStyles();
  const [upcomingExpanded, setUpcomingExpanded] = React.useState<boolean>(true);
  const [contactModalOpen, setContactModalOpen] =
    React.useState<boolean>(false);
  const [demographicModalOpen, setDemographicModalOpen] =
    React.useState<boolean>(false);
  const [currentReport, setCurrentReport] = React.useState();
  const [files, setFiles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const { data: ReportsData } = useQuery(GET_REPORTS, {
    variables: { patientId },
  });
  const { triggerSnack } = useSnackbar();
  const [addReport, { loading: addReportLoading }] = useMutation(ADD_REPORT, {
    refetchQueries: [{ query: GET_REPORTS, variables: { patientId } }],
    awaitRefetchQueries: false,
  });
  const [deleteReport] = useMutation(DELETE_REPORT, {
    refetchQueries: [{ query: GET_REPORTS, variables: { patientId } }],
    awaitRefetchQueries: false,
  });
  const { doctor } = useAuth();

  async function handleAddReport(file: File) {
    await addReport({
      variables: {
        ReportInput: {
          patientID: patientId,
          doctorID: doctor?._id,
        },
        attachment: file[0]?.file,
      },
      context: {
        hasUpload: true,
      },
    });
  }

  function handleDeleteReport(reportId: string) {
    deleteReport({ variables: { reportId } })
      .then(() => {})
      .catch((e) => log('error', e));
  }
  const { data: upcomingAppointments, loading: nextLoading } = useQuery(
    UPCOMING_APPOINTMENTS_QUERY,
    {
      variables: { patientId, page: 1, limit: 1 },
    }
  );

  const upcomingAppt =
    upcomingAppointments?.patientUpcomingAppointments?.result &&
    upcomingAppointments?.patientUpcomingAppointments?.result[0];
  const timeZone = upcomingAppt?.doctor?.timeZone;
  const upcomingSession = transformObjectTimeZone(upcomingAppt, timeZone);

  const contactSchema = yup.object().shape({
    email: yup.string().email().required(),
    phone: yup.string(),
    city: yup.string(),
    address: yup.string(),
    suite: yup.string(),
    zipcode: yup.string(),
    country: yup
      .string()
      .nullable()
      .transform((value) => (value === null ? '' : value)),
  });
  const demographicSchema = yup.object().shape({
    emergencyName: yup.string(),
    emergencyEmail: yup.string(),
    emergencyPhone: yup.string().nullable(),
  });
  const methodsContact = useForm({
    resolver: yupResolver(contactSchema),
    defaultValues: {
      ...PatientData?.patient,
    },
  });

  const methodsDemographics = useForm({
    resolver: yupResolver(demographicSchema),
    defaultValues: {
      ...PatientData?.patient,
      birthday: timeConverter(
        PatientData?.patient?.birthday ||
          new Date().getMilliseconds().toString()
      ),
    },
  });
  React.useEffect(() => {
    if (PatientData?.patient) {
      methodsDemographics.reset({
        ...PatientData?.patient,
        birthday: timeConverter(
          PatientData?.patient?.birthday ||
            new Date().getMilliseconds().toString()
        ),
      });
    }
  }, [PatientData?.patient, methodsDemographics.reset]);

  React.useEffect(() => {
    if (PatientData?.patient) {
      methodsContact.reset({
        ...PatientData?.patient,
        birthday: timeConverter(
          PatientData?.patient?.birthday ||
            new Date().getMilliseconds().toString()
        ),
      });
    }
  }, [PatientData?.patient, methodsContact.reset]);

  const onDescriptionSubmit = async (description: string) => {
    const {
      firstname,
      lastname,
      email,
      description: old,
    } = PatientData?.patient || {};

    if (old !== description && description !== '') {
      const result = await updatePatient(
        {
          EditPatientInput: { description, firstname, lastname, email },
          picture: undefined,
          patientId,
        },
        (e) => log('error', e)
      );
      if (!result) return;
      triggerSnack();
      setTimeout(() => setContactModalOpen(false), 1500);
    }
  };

  const onContactSubmit = async (data: any) => {
    const { firstname, lastname } = PatientData?.patient || {};
    const result = await updatePatient(
      {
        EditPatientInput: { ...data, firstname, lastname },
        picture: undefined,
        patientId,
      },
      (e) => log('error', e)
    );
    if (!result) return;
    triggerSnack();
    setTimeout(() => setContactModalOpen(false), 1500);
  };
  async function onDemographicSubmit(data) {
    const { firstname, lastname, email } = PatientData?.patient || {};
    const result = await updatePatient(
      {
        EditPatientInput: { ...data, firstname, email, lastname },
        picture: undefined,
        patientId,
      },
      (e) => log('error', e)
    );
    if (!result) return;
    triggerSnack();
    setTimeout(() => setDemographicModalOpen(false), 1500);
  }

  return (
    <DataLoader error={error} data={PatientData}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
          <Accordion
            square
            expanded={upcomingAppt && !loading ? upcomingExpanded : false}
            onChange={() =>
              upcomingAppt && !loading && setUpcomingExpanded((s) => !s)
            }
            className={
              upcomingAppt && !loading
                ? classes.summary
                : classes.summaryTopArrow
            }
            AccoridonSummary={
              <Box display="flex" alignItems="flex-start">
                <UpcomingIcon />
                <Box ml={1}>
                  <Text i18nKey="upcomingSessions" className={classes.title}>
                    Upcoming session
                  </Text>
                  <Skeleton loading={loading}>
                    {!upcomingAppt && (
                      <Text
                        i18nKey="No session scheduled yet"
                        className={classes.noApointment}
                      >
                        No session scheduled yet
                      </Text>
                    )}
                  </Skeleton>
                  <Skeleton loading={loading}>
                    <Text
                      i18nKey="+ Schedule new session"
                      className={classes.subtitle}
                      onClick={(e) => {
                        if (isEnabled) {
                          e.stopPropagation();
                          setPatientAdded(PatientData?.patient);
                          handleOpenAddModal();
                        }
                      }}
                      disabled={!isEnabled}
                      style={{ opacity: isEnabled ? 1 : 0.4 }}
                    >
                      + Schedule new session
                    </Text>
                  </Skeleton>
                </Box>
              </Box>
            }
          >
            <Box
              sx={{ visibility: upcomingAppt ? 'visible' : 'hidden' }}
              mx={2}
            >
              <ApptCard event={upcomingSession} loading={nextLoading} />
            </Box>
          </Accordion>
          <GeneralInfo
            data-cy="general-info"
            loading={loading}
            {...PatientData?.patient}
            generalInformation={generalInformation}
            setContactModalOpen={setContactModalOpen}
            setDemographicModalOpen={setDemographicModalOpen}
            setCurrentReport={setCurrentReport}
            setOpen={setOpen}
            reportsData={ReportsData ? ReportsData?.reports : []}
            addReportLoading={addReportLoading}
            handleAddReport={handleAddReport}
            handleDeleteReport={handleDeleteReport}
            files={files}
            onDescriptionSubmit={onDescriptionSubmit}
          />
        </Grid>
        <Grid item xl={9} lg={8} md={12} sm={12} xs={12}>
          <Feed patientId={patientId} />
        </Grid>
        <FormProvider {...methodsContact}>
          <BasicModal
            open={contactModalOpen}
            onClose={() => {
              setContactModalOpen(false);
            }}
            handleClose={() => {
              setContactModalOpen(false);
            }}
            isFromModal
            isSlide
            divider
            title={t('Contact information')}
            titlePadding="2rem"
            onSubmit={onContactSubmit}
            loading={loadingPatient}
          >
            <Box
              sx={{
                width: {
                  xs: '50vw',
                  lg: '43vw',
                  xl: '35vw',
                },
              }}
            >
              <ContactModal patient={PatientData?.patient} />
            </Box>
          </BasicModal>
        </FormProvider>

        <FormProvider {...methodsDemographics}>
          <BasicModal
            open={demographicModalOpen}
            onClose={() => {
              setDemographicModalOpen(false);
            }}
            handleClose={() => {
              setDemographicModalOpen(false);
            }}
            isFromModal
            isSlide
            divider
            title={t('Demographics information')}
            titlePadding="2rem"
            onSubmit={onDemographicSubmit}
            loading={loadingPatient}
          >
            <Box
              sx={{
                width: {
                  xs: '50vw',
                  lg: '43vw',
                  xl: '35vw',
                },
              }}
            >
              <DemographicsModal patient={PatientData?.patient} />
            </Box>
          </BasicModal>
        </FormProvider>
      </Grid>
    </DataLoader>
  );
}
