import React, { forwardRef, useState } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  withStyles,
} from '@material-ui/core';
import { IEmoji } from 'pages/PatientActivities/commun/Form';

interface CustomSelectProps {
  values: IEmoji[];
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
  setOpenDP?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
}

const FormInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    maxHeight: '40px',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    fontSize: '16px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: '8px',
      border: '1px solid #082CAF',
    },
  },
}))(InputBase);

const IconSelect = forwardRef<HTMLDivElement, CustomSelectProps>(
  (
    { selectedValue, setSelectedValue, values, setOpenDP, handleClose },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div ref={ref}>
        <FormControl style={{ width: '100%' }}>
          <Select
            value={selectedValue}
            open={isOpen}
            onOpen={(event) => {
              event.stopPropagation();
              setIsOpen(true);
              setOpenDP?.(false);
            }}
            onClose={() => {
              setIsOpen(false);
              setOpenDP?.(true);
              handleClose?.();
            }}
            input={<FormInput />}
            MenuProps={{
              disableScrollLock: false,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              PaperProps: {
                style: {
                  maxHeight: '300px',
                  overflowY: 'auto',
                },
              },
            }}
          >
            {values.map((value) => (
              <MenuItem
                key={value.label}
                value={value.label}
                onClick={() => {
                  setSelectedValue(value.label);
                  setIsOpen(false);
                  setOpenDP?.(true);
                  handleClose?.();
                }}
              >
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  style={{
                    gap: '0.1rem',
                  }}
                >
                  <Box
                    maxWidth="25px"
                    minWidth="25px"
                    maxHeight="25px"
                    minHeight="25px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {value.icon && value.icon}
                  </Box>
                  {value.label}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
);

export default IconSelect;
