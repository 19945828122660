import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from '../styles';
import BasicModal from 'components/ui/Modal';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import Table from 'components/ui/Table';
import { convertMsToDate, dateIsExpired } from 'utils/dateUtils';
import { IColumn } from 'components/ui/Table/types';
import { ReactComponent as EmptyForm } from 'assets/emptyState/form.svg';
import { ReactComponent as EyeIcon } from 'assets/patientActivities/smallEye.svg';
import { ReactComponent as MeesageIcon } from 'assets/patientActivities/message.svg';
import Menu from 'components/ui/Menu';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import TrashIcon from 'assets/TrashIcon';
import { gql, useQuery } from '@apollo/client';
import { IDoctorTemplates } from './FormModal';
import useDeleteQuestionnaire from '@eggmed/graphql-client/operations/patientActivitiesOperations/useDeleteQuestionnaire';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { useHistory, useLocation } from 'react-router-dom';
import { useProvider } from 'pages/InboxPage/state/Provider';
import ReminderModal from './ReminderModal';
import { useProvider as useFormProvider } from './ActivityProvider';
import FormModals from './FormModals';
import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/edit-icon.svg';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { ReactComponent as Upload } from 'assets/uploadD.svg';
import { ReactComponent as AlarmRemind } from 'assets/AlarmRemind.svg';
import FormPage from 'pages/FormsPage';
import EmptyState from 'pages/DashboardPage/EmptyState';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import NoteTakingModal from 'components/ui/Modal/NoteTakingModal';
import NewForm from 'pages/PatientActivities/Questionaires/NewForm';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateEditForm } from 'pages/PatientActivitiesPage/CreateEditForm';
import { useSnackbar } from 'hooks/useSnackbar';
import useEditQuestionnaire from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditQuestionnaire';
import {
  GET_PATIENT_ACTIVITY,
  QUESTIONNAIRE_BY_PATIENT_ID,
} from 'pages/PatientActivitiesPage';
import { GET_PATIENT } from 'pages/PatientProfilePage';

interface IQuestionairesProps {
  patient?: IPatientData;
  disableAdd?: boolean;
  doctorAndPatientId?: { doctor: string; patient: string };
  isPatient?: boolean;
}

function Questionaires({
  patient,
  disableAdd = false,
  doctorAndPatientId,
  isPatient,
}: IQuestionairesProps) {
  const { t } = useTranslation();
  const { handleOpenCreateNewThreadModal } = useProvider();
  const [openSlide, setOpenSlide] = React.useState(false);
  const { setOpenForm, refetch } = useFormProvider();
  const classes = useStyles({ selected: false });
  const [openReminder, setOpenReminder] = React.useState(false);
  const [menuType, setMenuType] = React.useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(5);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDeleteQuestionnaire, setOpenDeleteQuestionnaire] =
    React.useState(false);
  const [handleDeleteQuestionnaire] = useDeleteQuestionnaire();
  const [createEditOpen, setCreateEditOpen] = React.useState<boolean>(false);
  const [selectedForm, setSelectedForm] =
    React.useState<IDoctorTemplates>(null);
  const [formResponse, setFormResponse] = React.useState(selectedForm);
  const location = useLocation<{ refetch: boolean }>();
  const { triggerSnack } = useSnackbar();
  const [handleEditQuestionnaire, { loading: formLoading }] =
    useEditQuestionnaire();
  const methods = useForm();
  useEffect(() => {
    if (location.state?.refetch) {
      refetch();
    }
  }, [location?.state]);
  useEffect(() => {
    setFormResponse(selectedForm);
  }, [selectedForm]);
  const { open: isSigning, setBlocks } = useEditorContext();
  const { data: forms, loading } = useQuery(QUESTIONNAIRE_BY_FILTER, {
    variables: {
      patientId: patient?._id ?? doctorAndPatientId?.patient,
      doctorId: doctorAndPatientId?.doctor,
      page: page.toString(),
      limit: limit.toString(),
    },
  });

  const handleCloseReminder = () => {
    setOpenReminder(false);
  };
  const handleReminder = () => {
    // action here TBD
    handleCloseReminder();
  };
  function handlePagination(e: unknown, pageNumber: number) {
    setPage(pageNumber);
  }
  const openDownloadMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuType(true);
    setAnchorEl(event.currentTarget);
  };
  // const openActionsMenu = (
  //   event: React.MouseEvent<HTMLElement>,
  //   id: string
  // ) => {
  //   setSelectedForm(forms?.questionnairesfilter?.result.find((form) => form._id === id));
  //   setMenuType(false);
  //   setAnchorEl(event.currentTarget);
  // };
  const getFilteredQuestionaireById = (id: string) => {
    return forms?.questionnairesfilter?.result.find((form) => form._id === id);
  };
  const [form, setForms] = React.useState(null);
  const handleView = (id: string) => {
    const form = getFilteredQuestionaireById(id);
    setForms({
      formName: form.title,
      formDescription: form.description,
      formId: form._id,
      doctorId: form.doctor._id,
      patientId: patient?._id,
      previewMode: true,
      blocks: form?.blocks,
      isPatientSigned: form?.isPatientSigned,
    });
    setOpenSlide(true);
  };

  const handleEdit = (id: string) => {
    setMenuType(false);

    const form = getFilteredQuestionaireById(id);

    setSelectedForm(form);
    setForms({
      formName: form?.title,
      formDescription: form?.description,
      formQuestions: form?.questions,
      formId: form?._id,
      doctorId: form?.doctor?._id,
      patientId: patient?._id,
      assignText: `Assign this form to ${patient?.firstname} ${patient?.lastname}?`,
      editQuestionnaire: true,
    });
    setOpenSlide(true);
  };

  const handleEditForm = (id: string) => {
    const form = getFilteredQuestionaireById(id);
    setSelectedForm(form);
    setCreateEditOpen(true);
  };

  const handleCloseCreateEdit = () => {
    setCreateEditOpen(false);
    setError('');
  };
  const { data: patientSignature, loading: loadingSignature } = useQuery(
    GET_PATIENT,
    {
      variables: { patientId: patient?._id },
    }
  );
  const [error, setError] = React.useState('');
  const onSubmit = async (data) => {
    const { data: dataSign } =
      JSON.parse(formResponse.blocks).find((el) => el.type === 'Signature') ||
      {};
    const isSign =
      !!dataSign &&
      isSigning &&
      patientSignature?.patient?.signature?.picture &&
      !loadingSignature;
    const payload = {
      title: formResponse.title,
      description: formResponse.description,
      patientResponseDate: new Date(),
      blocks: formResponse.blocks,
      assignedAt: formResponse.assignedAt,
      doctor: formResponse.doctor._id,
      patient: patient?._id,
      isPatientSigned: isSign,
    };
    if (!!!dataSign) {
      await handleEditQuestionnaire({
        variables: { id: formResponse._id, questionnaireInput: payload },
        refetchQueries: [
          {
            query: QUESTIONNAIRE_BY_PATIENT_ID,
            variables: { patientId: patient?._id },
          },
          GET_PATIENT_ACTIVITY,
          QUESTIONNAIRE_BY_FILTER,
        ],
      });
      setError('');
    } else {
      if (isSign) {
        await handleEditQuestionnaire({
          variables: { id: formResponse._id, questionnaireInput: payload },
          refetchQueries: [
            {
              query: QUESTIONNAIRE_BY_PATIENT_ID,
              variables: { patientId: patient?._id },
            },
            GET_PATIENT_ACTIVITY,
            QUESTIONNAIRE_BY_FILTER,
          ],
        });
        setError('');
      } else {
        setError('Please add your signature');
        return;
      }
    }

    triggerSnack();
    handleCloseCreateEdit();
  };

  const columns: IColumn<any>[] = [
    {
      id: 'title',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="title">Title</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 150,
      freeze: true,
      format: (value) => value,
    },
    {
      id: 'assignedAt',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="assignmentDate">Assignment date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value) => {
        return convertMsToDate(value);
      },
    },
    {
      id: 'Status',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="status">Status</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value, { assignedAt, patientResponseDate }) => {
        const futureDate = new Date(assignedAt);
        futureDate.setDate(assignedAt && futureDate.getDate() + 30);
        const noResponseStatus = dateIsExpired(futureDate)
          ? 'overdue'
          : 'pending';
        const status = !!patientResponseDate ? 'done' : noResponseStatus;
        return (
          <>
            {status === 'done' ? (
              <div className={classes.done}>
                <Text i18nKey="done">Done</Text>
              </div>
            ) : status === 'overdue' ? (
              <div className={classes.overdue}>
                <Text i18nKey="overdue">Overdue</Text>
              </div>
            ) : (
              <div className={classes.pending}>
                <Text i18nKey="pending">Pending</Text>
              </div>
            )}
          </>
        );
      },
    },
    {
      id: 'Actions',
      label: '',
      align: 'left',
      minWidth: 100,
      disableSorting: true,
      freeze: true,
      format: (value, { _id, patientResponseDate, blocks }) => {
        const isResponse = !!patientResponseDate;
        return (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {isResponse && (
              <Eye
                onClick={() => handleView(_id)}
                // onClick={() => {
                //   setBlocks(blocks);
                //   setOpen(true);
                // }}
                style={{ cursor: 'pointer', marginRight: '15px' }}
              />
            )}
            {!isResponse && (
              <AlarmRemind
                onClick={() => setOpenReminder(true)}
                style={{ cursor: 'pointer', marginRight: '15px' }}
              />
            )}
            <DeleteIcon
              onClick={() => {
                setOpenDeleteQuestionnaire(true);
                setSelectedForm(getFilteredQuestionaireById(_id));
              }}
              style={{ cursor: 'pointer', marginRight: '15px' }}
            />
            {isPatient && (
              <Box
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  // handleEdit(_id);
                  handleEditForm(_id);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Edit />
              </Box>
            )}
          </Box>
        );
      },
    },
  ];
  const DownloadMenuElements = [
    {
      name: t('Export as PDF'),
      onClick: () => {},
      avatar: <EyeIcon />,
    },
  ];
  const ActionsMenuElements = [
    {
      name: t('Message'),
      onClick: () => {
        handleOpenCreateNewThreadModal();
        setAnchorEl(null);
      },
      avatar: <MeesageIcon />,
    },
    {
      name: t('Edit'),
      onClick: () => {
        setForms({
          formName: selectedForm.title,
          formDescription: selectedForm.description,
          formQuestions: selectedForm.questions,
          formId: selectedForm._id,
          doctorId: selectedForm.doctor._id,
          patientId: patient?._id,
          assignText: `Assign this form to ${patient?.firstname} ${patient?.lastname}?`,
          editQuestionnaire: true,
        });
        setOpenSlide(true);
      },
      avatar: <EditIcon />,
    },
    {
      name: t('Delete'),
      onClick: () => {
        setOpenDeleteQuestionnaire(true);
        setAnchorEl(null);
      },
      avatar: <TrashIcon width="18px" height="18px" />,
    },
  ];
  const deleteQuestionnaire = () => {
    handleDeleteQuestionnaire({
      variables: { id: selectedForm._id },
      refetchQueries: [
        {
          query: QUESTIONNAIRE_BY_FILTER,
          variables: {
            patientId: patient?._id ?? doctorAndPatientId?.patient,
            doctorId: doctorAndPatientId?.doctor,
            page: page.toString(),
            limit: limit.toString(),
          },
        },
      ],
    });
    setOpenDeleteQuestionnaire(false);
  };
  const [open, setOpen] = React.useState(false);
  const { handleChangeText, clearBlock } = useEditorContext();
  const [selectedProviders, setSelectedProviders] = React.useState<any>([]);
  return (
    <Box width="100%" data-cy="questionaire-tab">
      {/* {!disableAdd &&
        questionaires?.questionnaireByDoctorIdAndPatientId &&
        questionaires?.questionnaireByDoctorIdAndPatientId.length > 0 && (
          <PatientListHeader
            handleOpen={() => setOpenForm(true)}
            limit={limit}
            setLimit={setLimit}
            disableEntries
          />
        )} */}
      <Table
        fullData={false}
        loading={loading}
        page={page}
        columns={columns}
        limit={limit}
        handlePagination={handlePagination}
        data={forms?.questionnairesfilter?.result || []}
        count={forms?.questionnairesfilter?.count}
        pagination
        sorting
        EmptyState={
          <Box py="5rem">
            <EmptyState
              icon={<EmptyForm />}
              title={
                isPatient
                  ? t('No forms yet')
                  : `${t('Create forms for')} ${capitalizeFirstLetter(
                      patient?.firstname
                    )} ${t('to complete')}`
              }
              onClick={() => setOpenForm(true)}
              isText={false}
              hasAction={!isPatient}
              buttonText={t('Create form')}
            />
          </Box>
        }
      />
      <FormModals patient={patient} />
      <BasicModal
        open={openReminder}
        onClose={handleCloseReminder}
        isRegularModal
        title={t('Forms')}
        handleClose={handleCloseReminder}
        isIcon={false}
      >
        <ReminderModal
          handleClose={handleCloseReminder}
          handleSubmit={handleReminder}
        />
      </BasicModal>
      <ModalDelete
        text={t('Questionnaire')}
        open={openDeleteQuestionnaire}
        onClose={() => setOpenDeleteQuestionnaire(false)}
        onDelete={deleteQuestionnaire}
      />
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        MenuElements={menuType ? DownloadMenuElements : ActionsMenuElements}
        className={classes.menu}
      />
      <BasicModal
        open={openSlide}
        onClose={() => setOpenSlide(false)}
        handleClose={() => setOpenSlide(false)}
        isSlide
        divider
        title={''}
        titlePadding="0rem"
      >
        <FormPage
          isNotes={false}
          forms={form}
          settings
          handleClose={() => setOpenSlide(false)}
        />
      </BasicModal>
      <NoteTakingModal
        open={open}
        handleClose={() => {
          clearBlock();
          setOpen(false);
          handleChangeText('Untitled template');
        }}
      >
        <NewForm
          handleBack={() => {
            clearBlock();
            setOpen(false);
            handleChangeText('Untitled template');
          }}
        />
      </NoteTakingModal>
      <FormProvider {...methods}>
        <BasicModal
          open={createEditOpen}
          onClose={handleCloseCreateEdit}
          handleClose={handleCloseCreateEdit}
          isFromModal
          divider
          onSubmit={onSubmit}
          loading={formLoading}
          hasTitle={false}
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '50vw',
                xl: '35vw',
              },
              height: '70vh',
            }}
          >
            <CreateEditForm
              setError={setError}
              activityType={{
                assignedAt: selectedForm?.assignedAt,
                activity: selectedForm,
                type: 'form',
                source: 'Assign',
              }}
              isEditMode={false}
              formResponse={formResponse}
              setFormResponse={setFormResponse}
              error={error}
              selectedProviders={selectedProviders}
              setSelectedProviders={setSelectedProviders}
            />
          </Box>
        </BasicModal>
      </FormProvider>
    </Box>
  );
}

export default Questionaires;

export const DOCTOR_TEMPLATES = gql`
  query doctorTemplateByTypeForm(
    $formType: String
    $type: String
    $page: Int
    $limit: Int
  ) {
    doctorTemplateByTypeForm(
      formType: $formType
      type: $type
      page: $page
      limit: $limit
    ) {
      result {
        _id
        title
        description
        lastOpenedAt
        isDefault
        doctor {
          _id
        }
        formType
        blocks
      }
      count
    }
  }
`;
export const QUESTIONNAIRE_BY_FILTER = gql`
  query questionnairesfilter(
    $page: String
    $limit: String
    $patientId: ID!
    $doctorId: ID
  ) {
    questionnairesfilter(
      page: $page
      limit: $limit
      patientId: $patientId
      doctorId: $doctorId
    ) {
      count
      result {
        _id
        title
        description
        assignedAt
        patientResponseDate
        doctor {
          _id
        }
        blocks
        isPatientSigned
      }
    }
  }
`;
