import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  InputBase,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/form/toolbar/edit.svg';
import { ReactComponent as Valid } from 'assets/form/toolbar/valid.svg';
import { ReactComponent as NotValid } from 'assets/form/toolbar/not-valid.svg';
import { useEditorContext } from '../EditorContextProvider';
import { truncateText } from 'utils/FormatText';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '0.625rem',
    marginLeft: '0.3rem',
    width: '100%',
    maxWidth: '15.625rem',
    minWidth: '15.625rem',
  },
  input: {
    flex: '1',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: '#4F4F4F',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    padding: '10px 14px',
    gap: '10px',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  boxTitle: {
    flex: '1', // Ensures title takes available space
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  text: {
    textOverflow: ' [..]',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: '#4F4F4F',
  },
  iconButton: {
    flex: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  tooltip: {
    borderRadius: '8px',
    backgroundColor: '#101828',
    fontFamily: 'Inter, sans-serif',
    color: '#FFFFFF',
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '12px',
    textAlign: 'left',
  },
}));

const EditableText = () => {
  const { handleChangeText, formTitle } = useEditorContext();

  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(formTitle);
  const inputRef = useRef(null);

  const handleSave = useCallback(
    (e) => {
      e.preventDefault();
      setIsEditing(false);
      title === ''
        ? handleChangeText('Untitled template')
        : handleChangeText(title);
    },
    [handleChangeText, title]
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSave(e);
      }
    };

    if (isEditing) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSave, isEditing, title]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditing(false);
    setTitle(formTitle);
  };
  const handleBlur = (e) => {
    handleSave(e);
  };
  const handleValidText = () => {
    // Perform actions with the current text if needed
  };

  return (
    <div ref={inputRef} onBlur={handleBlur}>
      <Box className={classes.root}>
        {isEditing ? (
          <InputBase
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={classes.input}
            fullWidth
          />
        ) : (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={formTitle}
            placement="top-start"
          >
            <Box className={classes.boxTitle}>
              <Typography className={classes.text}>
                {truncateText(formTitle, 20)}
              </Typography>
            </Box>
          </Tooltip>
        )}
        <Box className={classes.iconButton} onClick={handleEditClick}>
          {!isEditing && <EditIcon />}
          {isEditing && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <Valid
                onMouseDown={(e) => e.preventDefault()}
                onClick={handleSave}
              />
              <NotValid
                onMouseDown={(e) => e.preventDefault()}
                onClick={handleCancel}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default EditableText;
