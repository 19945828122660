import { Box } from '@material-ui/core';
import BasicModal from 'components/ui/Modal';
import React from 'react';
import DeleteModalContent from './DeleteModalContent';

interface IModalDeleteProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  text?: string;
  isPatient?: boolean;
  message?: string;
  isArchived?: boolean;
  loading?: boolean;
  archive?: boolean;
  name?: string;
}

const ModalDelete: React.FC<IModalDeleteProps> = ({
  open,
  onClose,
  onDelete,
  text,
  isPatient = false,
  message,
  loading = false,
  archive = false,
  isArchived = false,
  name,
}) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      handleClose={onClose}
      isRegularModal
      isNew
      isIcon
    >
      <Box bgcolor="white">
        <DeleteModalContent
          handleClose={onClose}
          archive={archive}
          handleDelete={onDelete}
          text={text}
          name={name}
          isArchived={isArchived}
          message={message}
          isPatient={isPatient}
          loading={loading}
        />
      </Box>
    </BasicModal>
  );
};
export default ModalDelete;
