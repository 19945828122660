import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  checkboxSecondaryChecked: {
    color: '#FFFFFF !important',
  },
  textShared: {
    fontSize: '14px',
    color: '#344054',
    fontFamily: 'Inter',
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    marginRight: '5px',
  },
  link: {
    color: '#147AF3 !important',
    cursor: 'pointer',
    fontSize: '16px',
    minWidth: '80px',
    textAlign: 'right',
    fontFamily: 'Inter',
    fontWeight: 500,
    textDecoration: 'underline',
    '& .MuiInputBase-input': {
      color: '#147AF3 !important',
      cursor: 'pointer',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    '& .MuiFormHelperText-root': {
      border: '0px !important',
    },

    '& .Mui-focused': {
      border: 'none !important',
      boxShadow: 'none !important',
    },
  },
  notchedOutline: {
    border: 'none !important',
  },
  background: {
    background: 'white',
    borderRadius: '16px',
    padding: '24px !important',
  },
  nextButton: {
    border: '1px solid #DEE2EC',
    color: '#425364',
    backgroundColor: 'white',
    width: '20.7px',
    height: '20.7px',
    fontSize: '16px',
    borderRadius: '10px',
    // margin: theme.spacing(1),
  },
  datePicker: {
    width: '100%',
    '& div.react-datepicker-wrapper': {
      width: '100%',
      zIndex: 1000000000000000,
    },
    '& .react-datepicker-popper': {
      zIndex: 50000000000000,
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: '#39C38D',
      color: 'white !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day-name': {
      color: '#425364',
      fontWeight: 500,
    },
    '& .react-datepicker__header': {
      backgroundColor: '#ffffff',
      borderBottom: '0px',
    },
    '& .react-datepicker__day': {
      color: '#425364',
      fontWeight: 500,
    },
    '& .react-datepicker__day--outside-month': {
      color: '#949494',
      fontWeight: 500,
    },
    '& .react-datepicker__day[aria-disabled="true"]': {
      color: '#949494',
      fontWeight: 500,
    },
    '& .react-datepicker__triangle': {
      borderBottom: '1px solid #ffffff',
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
      {
        borderBottom: '1px solid #fbfbfb',
        display: 'none',
      },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: 'white !important',
    },
  },
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  signText: {
    fontWeight: 600,
    fontSize: '16px',
    fontFamily: 'Inter',
    lineHeight: '18px',
    color: '#0054B6',
    textDecoration: 'underline',
  },
  signDesc: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: '#0054B6',
  },
  titleBarLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '25px',
    fontWeight: 600,
    color: '#101828',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  recentWrapper: {
    flex: 9,
    flexBasis: '85%',
    height: '47vh',
    maxWidth: '65%',
  },

  boxAct: {
    [theme.breakpoints.only('sm')]: {
      width: '200px',
    },
    [theme.breakpoints.only('md')]: {
      width: '280px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '380px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '450px',
    },
  },
  assignedWrapper: {
    flex: 3,
  },
  pagination: {
    display: 'none',
  },
  arrows: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    '&:disabled': {
      opacity: 0.5,
    },
  },
  formHeadTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 700,
    color: '#4F4F4F',
  },
  formHeadSubtitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    color: '#475467',
  },
  attachBox: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.3rem',
    width: 'fit-content',
    marginInline: 'auto',
    cursor: 'pointer',
  },
  attachImg: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#2F80ED',
    textDecoration: 'underline',
  },
  textField: {
    width: '100%',
    '& fieldset': {
      border: '1px solid #EAECF0 !important',
      borderRadius: '8px',
    },
  },
  errorIcon: {
    fontSize: 14,
    marginRight: '1px',
  },
  label: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    color: '#4F4F4F',
    textAlign: 'center',
    marginBottom: 15,
  },
  label2: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: 10,
  },
  checkbox: {
    '& .MuiTypography-body1': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      color: '#425364',
    },
  },
  checkboxContainer: {
    '& label': {
      marginRight: 0,
      marginLeft: 0,
    },
    flexDirection: 'row',
    gap: '7px',
  },
  emojiText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#CED5EF',
  },
  emojiBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.3rem',
    cursor: 'pointer',
    width: 60,
    height: 65,
  },
  emojiTextSelected: {
    fontWeight: 700,
    color: '#082CAF',
    marginTop: '7px',
  },
  emojiIconSelected: {
    backgroundColor: '#082CAF',
    borderRadius: '50%',
    transform: 'scale(1.5)',

    '& path:nth-child(4)': {
      fill: '#082CAF',
      stroke: '#082CAF',
    },
  },
  viewTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 500,
    color: '#4F4F4F',
  },
  viewDate: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    color: '#475467',
  },
  viewQuestion: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    color: '#525F70',
  },
  viewQuestion2: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    color: '#525F70',
    marginBottom: '0.5rem',
  },
  viewResponse: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    color: '#4F4F4F',
  },
  viewImg: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '400px',
    objectFit: 'cover',
  },
  assignedCard: {
    border: '1px solid #EAECF0',
    borderRadius: 4,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  assignCardTitle: {
    color: '#4F4F4F',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
  },
  assignCardDate: {
    color: '#4F4F4F',
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
  },
  assignCardAction: {
    color: '#2F80ED',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  selectedFilters: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',
  },
  selectedFilter: {
    border: '1px solid #D0D5DD',
    backgroundColor: '#fff',
    borderRadius: '6px',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
  },
  selectedFilterText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  editFormHead: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    color: '#41A8FF',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  formQuestion: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    color: '#4F4F4F',
    marginBottom: '15px',
  },
  formScaleText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#425364',
  },
  formScaleTextLeft: {
    marginRight: '1.5rem',
  },
  formRadio: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#425364',
  },
  imgContainer: {
    position: 'relative',
    borderRadius: 12,
    overflow: 'hidden',
  },
  img: {
    objectFit: 'cover',
  },
  blueStrip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: '#1F61DCB2',
    height: '25%',
    width: '100%',

    '& svg': {
      marginRight: '10px',
    },
  },
  titleIllus: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    color: '#101828',
  },
  descriptionIllus: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
  ctaIllus: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'underline',
    color: '#41A8FF',
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
    cursor: 'pointer',
  },
  tabHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  tab: {
    marginBottom: '16px',
  },
  headerTab: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  addressWrapper: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    gap: '8px',
  },
  titleStyle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#344054',
  },
  wrapperBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  wrapperBoxCountry: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
  lowerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
  },
  placeholder: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#98A2B3',
  },
  scrollbar: {
    overflowY: 'scroll',
    overflowX: 'hidden',

    [theme.breakpoints.down('lg')]: {
      height: '31.5vh',
      maxHeight: '31.5vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '37.5vh',
      maxHeight: '37.5vh',
    },
    [theme.breakpoints.only('xl')]: {
      height: '40.9vh',
      maxHeight: '40.9vh',
    },
  },
}));
