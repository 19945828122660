import React from 'react';
import { Box, FilledTextFieldProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ErrorIcon from '@material-ui/icons/Error';
import Input from 'components/ui/Inputs';
import { useStyles } from './Payouts';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

interface IInputBlockProps
  extends Omit<FilledTextFieldProps, 'variant' | 'error'> {
  register: any;
  control: any;
  errors: any;
  label?: string;
  placeholder?: string;
  name: string;
  currentRow?: any;
  editMode?: boolean;
  type?: string;
  options?: string[];
  isAddedComponent?: React.ReactElement;
  isBigger?: boolean;
  isStripeSupportedCountry?: boolean;
  isAllSupported?: boolean;
  isFree?: boolean;
  error?: string | boolean;
  setError?: (name: string, error: FieldError) => void;
}

export default function InputBlock({
  label,
  name,
  errors,
  control,
  register,
  currentRow,
  editMode,
  isAddedComponent,
  type = 'text',
  isFree = false,
  setError,
  error,
  ...props
}: IInputBlockProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const hasError = error || (errors && !!errors[name]);
  const errorMessage = error || (errors && errors[name]?.message);

  return (
    <Box mb={2} className={props.className}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={!isAddedComponent && classes.margin}
      >
        <Typography
          className={clsx(classes.textTitle)}
          style={{ opacity: isFree ? 0.4 : 1 }}
        >
          {label}
        </Typography>
        <Box>{isAddedComponent && isAddedComponent}</Box>
      </Box>
      <Input
        inputRef={register}
        control={control}
        name={name}
        variant="filled"
        type={type}
        error={hasError}
        defaultValue={(editMode && currentRow && currentRow[name]) || ''}
        helperText={
          hasError && (
            <Box mt={0.5} data-cy="error-message" className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {t(errorMessage)}
            </Box>
          )
        }
        isBigger={props?.isBigger}
        {...props}
      />
    </Box>
  );
}
