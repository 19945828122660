import React, { useEffect, useState } from 'react';
import BasicModal from 'components/ui/Modal';
import ScrollBar from 'components/ui/ScrollBar';
import { ITeam } from './TeamSettingsTable';
import TeamMemberForm from './TeamMemberForm';
import { FormProvider, useForm } from 'react-hook-form';
import useAddTeamMember from '@eggmed/graphql-client/operations/doctorOperations/useAddTeamMember';
import useUpdateTeamMember from '@eggmed/graphql-client/operations/doctorOperations/useUpdateTeamMembers';
import { useSnackbar } from 'hooks/useSnackbar';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { Box } from '@material-ui/core';
import {
  convertObjetFormatObj,
  convertObjectsFormatStaffs,
} from 'pages/SchedulePage/AddEventPage/utils';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  values: ITeam;
  editMode: boolean;
  roles: [string];
  teamsMember: any;
  selectedSupervisors: { _id: string };
  setSelectedSupervisors: (arg: string) => void;
}

const schema = yup.object().shape({
  firstname: yup.string().required('Firstname is a required field'),
  lastname: yup.string().required('Last name is a required field'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is a required field'),
  role: yup.string().required('Role is a required field'),
});

function EditModal({
  open,
  onClose,
  values,
  editMode,
  roles,
  teamsMember,
  selectedSupervisors,
  setSelectedSupervisors,
}: IEditProps) {
  const { triggerSnack } = useSnackbar();
  const { addStaffToOrganization, loading } = useAddTeamMember();
  const { updatedStaffOrg, loading: loadingUpdade } = useUpdateTeamMember();
  const [selectedPatients, setSelectedPatients] = React.useState<any[]>([]);

  const [selectedColor, setSelectedColor] = React.useState(null);

  React.useEffect(() => {
    if (!editMode) {
      setSelectedSupervisors(null);
      setSelectedColor(null);
      setSelectedPatients([]);
    }
  }, []);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...values,
      npi: values?.doctorData?.providerSettings.npi,
      licensenumber: values?.doctorData?.providerSettings?.licenseNumber,
    },
  });
  const { setError } = methods;
  React.useEffect(() => {
    if (editMode) {
      methods.reset({
        ...values,
        npi: values?.doctorData?.providerSettings.npi,
        licensenumber: values?.doctorData?.providerSettings?.licenseNumber,
      });
      setSelectedColor(values?.colorCalendar);
      setSelectedSupervisors(convertObjetFormatObj(values?.supervisor));
      setSelectedPatients(
        convertObjectsFormatStaffs(values?.patientsRelated, '')
      );
    }
  }, [editMode, values]);
  async function handeAddTeamMember(data) {
    const relatedTo = selectedPatients?.map((el) => el?._id);
    const { npi, licensenumber, ...rest } = data || {};
    if (!editMode) {
      try {
        await addStaffToOrganization({
          variables: {
            staffInput: {
              ...rest,
              username: `${rest?.firstname} ${rest?.lastname}`,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              providerSettings: {
                licenseNumber: licensenumber,
                npi,
              },
              relatedTo,
              colorCalendar: selectedColor,
              supervisor: selectedSupervisors?._id,
            },
          },
        });
        onClose();
        triggerSnack('Team member added successfully');
      } catch (error) {
        setError('email', {
          type: 'manual',
          message: error.message,
        });
      }
    } else {
      try {
        await updatedStaffOrg({
          variables: {
            id: values?._id,
            staffInput: {
              ...rest,
              providerSettings: {
                licenseNumber: licensenumber,
                npi,
              },
              relatedTo,
              colorCalendar: selectedColor,
              supervisor: selectedSupervisors?._id,
            },
          },
        });
        onClose();
        triggerSnack('Team member updated successfully');
      } catch (error) {
        setError('email', {
          type: 'manual',
          message: error.message,
        });
      }
    }
  }
  return (
    <FormProvider {...methods}>
      <BasicModal
        open={open}
        title={editMode ? 'Edit info' : 'Add a team member'}
        titlePadding="2rem"
        onClose={onClose}
        handleClose={onClose}
        isFromModal
        isSlide
        divider
        onSubmit={handeAddTeamMember}
        loading={loading || loadingUpdade}
      >
        <Box width="40vw">
          <TeamMemberForm
            editMode={editMode}
            selectedPatients={selectedPatients}
            setSelectedPatients={setSelectedPatients}
            roles={roles}
            teamsMember={teamsMember}
            selectedSupervisors={selectedSupervisors}
            setSelectedSupervisors={setSelectedSupervisors}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            setError={setError}
          />
        </Box>
      </BasicModal>
    </FormProvider>
  );
}

export default EditModal;
