import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import TeamSettingsHeader from './TeamSettingsHeader';
import TeamSettingsTable from './TeamSettingsTable';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as PasswordIcon } from 'assets/passcode.svg';
import TrashIcon from 'assets/TrashIcon';
import Menu from 'components/ui/Menu';
import AddEditModal from './EditModal';
import PasswordModal from './PasswordModal';
import RemoveModal from './RemoveModal';
import RoleModal from './RoleModal';
import { useQuery, gql } from '@apollo/client';
import useUpdateStaffRole from '@eggmed/graphql-client/operations/staffOperations/useUpdateStaffRole';
import useDeleteStaffMmber from '@eggmed/graphql-client/operations/staffOperations/useDeleteStaffMember';
import { useSnackbar } from 'hooks/useSnackbar';
import { useHistory } from 'react-router-dom';
import useAuth from 'graphql/operations/doctorOperations/useAuth';

function TeamSettings() {
  const [selectedSupervisors, setSelectedSupervisors] =
    React.useState<any>(null);
  const { triggerSnack } = useSnackbar();
  const history = useHistory();
  const { doctor } = useAuth();
  const [role, setRole] = useState('');
  const [inviteStatus, setInviteStatus] = useState('');
  const { data, loading } = useQuery(TEAM_MEMBERS, {
    variables: {
      role: role,
      status: inviteStatus,
    },
  });
  const { data: userRoles, loading: loadingRole } = useQuery(USER_ROLES);
  const roles = userRoles?.getAllRoleByUser;
  const teamsMember = data?.teamSettings;
  const { handleUpdateStaffRole, loading: loadingCHangeRole } =
    useUpdateStaffRole();
  const { handleRemoveStaff, loading: loadingRemoveStaff } =
    useDeleteStaffMmber();
  const [filteredTeam, setFilteredTeam] = useState([]);
  React.useEffect(() => {
    if (teamsMember) {
      setFilteredTeam(teamsMember);
    }
  }, [teamsMember]);
  const [queryState, setQuery] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<string>('');

  const handleOpenModal = () => {
    setSelectedRow(null);
    setEditMode(false);
    setOpenModal(true);
  };
  const handleEditFields = (row) => {
    setEditMode(true);
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleSearch = (query) => {
    setQuery(query);
    const newTeam = teamsMember?.filter((item) => {
      return (
        item.firstname?.toLowerCase().includes(query.toLowerCase()) ||
        item.lastname?.toLowerCase().includes(query.toLowerCase()) ||
        item.email?.toLowerCase().includes(query.toLowerCase()) ||
        item.Supervisor?.firstname
          ?.toLowerCase()
          .includes(query.toLowerCase()) ||
        item.Supervisor?.lastname?.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredTeam(newTeam);
  };

  const handleClear = () => {
    setQuery('');
    setFilteredTeam(teamsMember);
  };

  const displayedTeam = filteredTeam?.filter((item) => {
    const matchesRole = role ? item.role === role : true;
    const matchesInviteStatus = inviteStatus
      ? item.inviteStatus === inviteStatus
      : true;
    return matchesRole && matchesInviteStatus;
  });

  const handleResetPassword = () => {
    setOpenResetPasswordModal(true);
  };
  const handleRemove = () => {
    setOpenRemoveModal(true);
  };
  const menuElements = [
    {
      avatar: <EditIcon />,
      name: 'Edit info',
      onClick: () => {
        setAnchor(null);
        setEditMode(true);
        setOpenModal(true);
      },
    },
    {
      avatar: <PasswordIcon />,
      name: 'Reset password',
      onClick: () => {
        setAnchor(null);
        handleResetPassword();
      },
    },
    {
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Remove',
      onClick: () => {
        setAnchor(null);
        handleRemove();
      },
      isRed: true,
    },
  ];
  const handleOpenDropDown = (e, row) => {
    setAnchor(e.currentTarget);
    setSelectedRow(row);
  };
  const handleChangeRole = (row, newRole) => {
    setNewRole(newRole);
    setOpenRoleModal(true);
    setSelectedRow(row);
  };
  async function onSubmit() {
    await handleUpdateStaffRole(selectedRow?._id, newRole);
    setOpenRoleModal(false);
    triggerSnack('Role updated successfully');
  }

  async function onDeleteStaff() {
    await handleRemoveStaff(selectedRow?._id);
    setOpenRemoveModal(false);
    triggerSnack('Team member deleted successfully');
  }

  return (
    <Grid container>
      <TeamSettingsHeader
        query={queryState}
        handleSearch={handleSearch}
        handleClear={handleClear}
        setRole={setRole}
        setInviteStatus={setInviteStatus}
        handleOpenModal={handleOpenModal}
        roles={roles}
      />
      <TeamSettingsTable
        team={filteredTeam}
        handleOpenDropDown={handleOpenDropDown}
        handleViewFields={(row) => {
          if (row?.doctorData?._id !== doctor?._id) {
            history.push(`/doctor/staff/${row?.doctorData?._id}`);
          } else {
            history.push(`/doctor/${row?.doctorData?._id}`);
          }
        }}
        handleEditFields={handleEditFields}
        handleChangeRole={handleChangeRole}
        loading={loading}
        roles={roles}
        query={queryState}
      />
      <Menu<any>
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchor}
        handleMenuClose={() => setAnchor(null)}
        MenuElements={menuElements}
      />
      <AddEditModal
        selectedSupervisors={selectedSupervisors}
        setSelectedSupervisors={setSelectedSupervisors}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setSelectedRow(null);
          setSelectedSupervisors(null);
        }}
        values={selectedRow}
        editMode={editMode}
        roles={roles}
        teamsMember={teamsMember}
      />
      <PasswordModal
        open={openResetPasswordModal}
        user={selectedRow}
        onClose={() => setOpenResetPasswordModal(false)}
      />
      <RemoveModal
        open={openRemoveModal}
        onClose={() => setOpenRemoveModal(false)}
        user={selectedRow}
        onSubmit={onDeleteStaff}
        loading={loadingRemoveStaff}
      />
      <RoleModal
        open={openRoleModal}
        onClose={() => setOpenRoleModal(false)}
        user={selectedRow}
        newRole={newRole}
        onSubmit={onSubmit}
        loading={loadingCHangeRole}
      />
    </Grid>
  );
}

export const TEAM_MEMBERS = gql`
  query teamSettings($role: String, $status: String) {
    teamSettings(role: $role, status: $status) {
      _id
      firstname
      lastname
      email
      memberStatus
      role
      lastRequest
      picture
      doctorId
      colorCalendar
      supervisor {
        _id
        firstname
        lastname
        picture
      }
      doctorData {
        _id
        firstname
        lastname
        providerSettings {
          npi
          licenseNumber
        }
      }
      patientsRelated {
        _id
        firstname
        lastname
        picture
      }
    }
  }
`;
export const USER_ROLES = gql`
  query getAllRoleByUser {
    getAllRoleByUser
  }
`;

export default TeamSettings;
