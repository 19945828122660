import {
  Box,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import React, { useState } from 'react';
import { ReactComponent as ArrowLeft } from 'assets/patient-profile/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/patient-profile/arrow-right.svg';
import Button from 'components/ui/Button';
import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';
import Menu from 'components/ui/Menu';
import { useStyles } from '../styles';
import Text from 'Text';
import { Empty } from '../Empty';
import clsx from 'clsx';
import { ReactComponent as RecentActivityIcon } from 'assets/patientActivities/recent-title.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseFilter } from 'assets/patient-profile/x-close.svg';
import { ReactComponent as EmptyRecent } from 'assets/patientActivities/activity-illus.svg';
import RecentActivityCard, {
  ActivitiesTypes,
  RecentActivityCardLoading,
} from 'pages/PatientActivities/RecentActivityCard';
import Checked from 'pages/AdminPage/Payment/Checked';
import { IActivitySource } from '..';
import { ReactComponent as MoodIcon } from 'assets/patientActivities/mood.svg';
import { ReactComponent as SleepIcon } from 'assets/patientActivities/sleep-2.svg';
import { ReactComponent as ReflectionIcon } from 'assets/patientActivities/reflection.svg';
import { ReactComponent as FormIcon } from 'assets/patientActivities/formIcon.svg';

const FormInput = withStyles((theme) => ({
  input: {
    minWidth: '60px',
    width: '100%',
    height: '22px',
    position: 'relative',
    backgroundColor: ' #FFF',
    borderRadius: '6px',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 4,
      border: '1px solid #0265DC',
    },
  },
}))(InputBase);

export const RecentActivities = ({
  recentActivity,
  loadingRecent,
  selectedValues,
  setSelectedValues,
  doctorAndPatientId,
  setActivityToOpen,
  handleOpenCreateEdit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    setSelectedValues(value as ActivitiesTypes[]);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const MenuElements = [
    {
      cy: 'mood-check-in',
      avatar: <MoodIcon />,
      name: t('Mood check-in'),
      onClick: () => {
        setActivityToOpen({
          source: IActivitySource.LOG,
          type: ActivitiesTypes.MOOD,
        });
        handleOpenCreateEdit();
        handleMenuClose();
      },
    },
    {
      cy: 'sleep-check-in',
      avatar: <SleepIcon />,
      name: t('Sleep check-in'),
      onClick: () => {
        setActivityToOpen({
          source: IActivitySource.LOG,
          type: ActivitiesTypes.SLEEP,
        });
        handleOpenCreateEdit();
        handleMenuClose();
      },
    },
    {
      cy: 'reflection',
      avatar: <ReflectionIcon />,
      name: t('Reflection'),
      onClick: () => {
        setActivityToOpen({
          source: IActivitySource.LOG,
          type: ActivitiesTypes.REFLECTION,
        });
        handleOpenCreateEdit();
        handleMenuClose();
      },
    },
    {
      cy: 'form',
      avatar: <FormIcon />,
      name: t('Form'),
      onClick: () => {
        setActivityToOpen({
          source: IActivitySource.LOG,
          type: ActivitiesTypes.FORM,
        });
        handleOpenCreateEdit();
        handleMenuClose();
      },
    },
  ];

  const values = [
    ActivitiesTypes.MOOD,
    ActivitiesTypes.SLEEP,
    ActivitiesTypes.REFLECTION,
    ActivitiesTypes.FORM,
  ];

  const valuesMapping = {
    moodCheckin: t('Mood check-ins'),
    sleepCheckin: t('Sleep check-ins'),
    reflection: t('Reflections'),
    form: t('Forms'),
  };

  return (
    <>
      <Splide
        options={{
          gap: '1rem',
          perPage: 3,
        }}
        hasTrack={false}
        aria-label="Activities"
      >
        <Box className={classes.titleBar}>
          <Box className={classes.titleBarLeft}>
            <Box display="flex" alignItems="center" gridGap="1rem">
              <RecentActivityIcon />
              <Text i18nKey="recentActivities" className={classes.title}>
                Recent activities
              </Text>
              <Box>
                <div className={clsx('splide__arrows', classes.arrows)}>
                  <button
                    className={clsx(
                      'splide__arrow splide__arrow--prev',
                      classes.arrow
                    )}
                  >
                    <ArrowLeft />
                  </button>
                  <button
                    className={clsx(
                      'splide__arrow splide__arrow--next',
                      classes.arrow
                    )}
                  >
                    <ArrowRight />
                  </button>
                </div>
              </Box>
            </Box>
            <Box>
              <FormControl>
                <Select
                  multiple
                  value={selectedValues}
                  onChange={handleChange}
                  displayEmpty
                  input={<FormInput />}
                  renderValue={(selected: ActivitiesTypes[]) =>
                    selected?.length > 0 ? (
                      <Box className={classes.selectedFilters}>
                        {(selected as ActivitiesTypes[]).map((value) => (
                          <Box key={value} className={classes.selectedFilter}>
                            <Typography className={classes.selectedFilterText}>
                              {valuesMapping[value]}
                            </Typography>
                            <CloseFilter />
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Typography className={classes.placeholder}>
                        {t('allActivities')}
                      </Typography>
                    )
                  }
                  MenuProps={{
                    getContentAnchorEl: () => null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: {
                      style: {
                        maxHeight: '300px',
                        overflowY: 'auto',
                      },
                    },
                  }}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  {values.map((value) => (
                    <MenuItem key={value} value={value}>
                      <Checked
                        color="#0265DC"
                        checkedValue={selectedValues.indexOf(value) != -1}
                        handleChange={() => {}}
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: '1rem' }}
                      >
                        {valuesMapping[value]}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Button
              className={classes.addBtn}
              buttonType="reset"
              onClick={handleClick}
            >
              <AddIcon />
              <Text
                style={{
                  color: 'white',
                  textTransform: 'unset',
                  display: 'flex',
                }}
                i18nKey="logActivity"
              >
                {t('Log activity')}
              </Text>
            </Button>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              handleMenuClose={handleMenuClose}
              MenuElements={MenuElements}
            />
          </Box>
        </Box>
        <Box>
          <SplideTrack>
            {loadingRecent && (
              <Box display="flex" width="100%" style={{ gap: '1rem' }}>
                {[1, 2, 3].map(() => (
                  <RecentActivityCardLoading />
                ))}
              </Box>
            )}
            {!loadingRecent &&
              recentActivity &&
              recentActivity.length === 0 && (
                <Box
                  width="100%"
                  minHeight="38vh"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Empty
                    icon={<EmptyRecent />}
                    title={t("No activity yet. Let's get started.")}
                    description={t('To log your first activity')}
                    isRecent={true}
                    anchorEl={anchorEl}
                    handleMenuClose={handleMenuClose}
                    MenuElements={MenuElements}
                  />
                </Box>
              )}
            {recentActivity &&
              recentActivity.length > 0 &&
              recentActivity?.map((element, id) => (
                <SplideSlide>
                  <RecentActivityCard
                    {...element}
                    doctorAndPatientId={doctorAndPatientId}
                    isFromClient={true}
                    setActivityToOpen={setActivityToOpen}
                    handleOpenCreateEdit={handleOpenCreateEdit}
                  />
                </SplideSlide>
              ))}
          </SplideTrack>
        </Box>

        <ul
          className={clsx(
            'splide__pagination splide__pagination--ltr',
            classes.pagination
          )}
          role="tablist"
        ></ul>
      </Splide>
    </>
  );
};
