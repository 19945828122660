import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { InputLabel, FormHelperText, Box, Grid } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Input from 'components/ui/Inputs';
import {
  convertToRawAndStringify,
  editorStateFromStringifiedRaw,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { timeConverter } from '../TimeConverter';
import { IUserProps } from './types';
import useStyles from './style';
import DataLoader from 'components/ui/DataLoader';
import Skeleton from 'components/ui/Skeleton';
import { EDIT_DOCTOR, GET_DOCTOR } from 'pages/DoctorProfilePage/graphql';
import { InfoBlock } from './InfoBlock';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import { InputBox } from './InputBox';

export enum BlockNames {
  legal = 'legalName',
  email = 'email',
  phone = 'phone',
  address = 'address',
  extra = 'extra',
  password = 'password',
  birth = 'birth',
  marital = 'marital',
  gender = 'gender',
  timeZone = 'timeZone',
  pronouns = 'pronouns',
  race = 'race',
  Religion = 'Religion',
  employment = 'employment',
  language = 'language',
  formatDate = 'formatDate',
  deleteAccount = 'deleteAccount',
  signature = 'signature',
  specialty = 'specialty',
  npi = 'npi',
  license = 'license',
  taxonomy = 'taxonomy',
  DPS = 'DPS',
  npiOrg = 'npiOrg',
  licenseOrg = 'licenseOrg',
  taxonomyOrg = 'taxonomyOrg',
  practiceName = 'practiceName',
  location = 'location',
  logo = 'logo',
  about = 'about',
  url = 'url',
}

const legalNameSchema = yup.object().shape({
  firstname: yup.string().required('First Name is a required field'),
  lastname: yup.string().required('Last Name is a required field'),
});

const emailSchema = yup.object().shape({
  email: yup.string().email().required('Email is a required field'),
});

const addressSchema = yup.object().shape({
  country: yup.string().required('Country is a required field'),
  city: yup.string().required('City is a required field'),
  zipcode: yup.string().required('Zip code is a required field'),
});

const PersonnalInformation = (): ReactElement => {
  const [errorEmail, setErrorEmail] = useState(null);
  const { triggerSnack } = useSnackbar();
  const { t } = useTranslation();

  const [editableBlock, setEditableBlock] = useState<BlockNames | null>(null);
  const [currentSchema, setCurrentSchema] = useState<yup.ObjectSchema>(
    yup.object().shape({})
  );

  const classes = useStyles();
  const { doctor } = useAuth();
  const { data, loading, error } = useQuery(GET_DOCTOR, {
    variables: { DoctorId: doctor._id },
  });
  const [editDoctor, { loading: loadingMutation }] = useMutation(EDIT_DOCTOR, {
    refetchQueries: [
      { query: GET_DOCTOR, variables: { DoctorId: doctor._id } },
    ],
    awaitRefetchQueries: false,
  });

  const { register, control, handleSubmit, reset, errors, watch } = useForm({
    resolver: yupResolver(currentSchema),
    defaultValues: useMemo(
      () => ({
        firstname: data?.doctor?.firstname,
        lastname: data?.doctor?.lastname,
        middlename: data?.doctor?.middlename,
        streetadress1: data?.doctor?.address,
        streetadress2: data?.doctor?.suite,
        city: data?.doctor?.city,
        provinceState: data?.doctor?.state,
        zipcode: data?.doctor?.zipcode,
        email: data?.doctor?.email,
        phoneNumber: data?.doctor?.phone,
        MartialStatus: data?.doctor?.MartialStatus,
        description: editorStateFromStringifiedRaw(
          data?.doctor?.about,
          !tryParseJSON(data?.doctor?.about)
        ),
        country: data?.doctor?.country,
        gender: data?.doctor?.gender,
        birthday: timeConverter(
          data?.doctor?.birthday || new Date().getMilliseconds().toString()
        ),
      }),
      [data]
    ),
  });

  const initialValues = useMemo(
    () => ({
      firstname: data?.doctor?.firstname,
      lastname: data?.doctor?.lastname,
      middlename: data?.doctor?.middlename,
      streetadress1: data?.doctor?.address,
      streetadress2: data?.doctor?.suite,
      city: data?.doctor?.city,
      provinceState: data?.doctor?.state,
      zipcode: data?.doctor?.zipcode,
      email: data?.doctor?.email,
      phoneNumber: data?.doctor?.phone,
      MartialStatus: data?.doctor?.MartialStatus,
      description: editorStateFromStringifiedRaw(
        data?.doctor?.about,
        !tryParseJSON(data?.doctor?.about)
      ),
      country: data?.doctor?.country,
      gender: data?.doctor?.gender,
      birthday: timeConverter(
        data?.doctor?.birthday || new Date().getMilliseconds().toString()
      ),
    }),
    [data]
  );

  const watchedVals = watch();

  const hasChanged = useMemo(() => {
    switch (editableBlock) {
      case BlockNames.legal:
        return (
          watchedVals.firstname !== initialValues.firstname ||
          watchedVals.lastname !== initialValues.lastname
        );
      case BlockNames.email:
        return watchedVals.email !== initialValues.email;
      case BlockNames.phone:
        return watchedVals.phoneNumber !== initialValues.phoneNumber;
      case BlockNames.address:
        return (
          watchedVals.country !== initialValues.country ||
          watchedVals.streetadress1 !== initialValues.streetadress1 ||
          watchedVals.streetadress2 !== initialValues.streetadress2 ||
          watchedVals.city !== initialValues.city ||
          watchedVals.provinceState !== initialValues.provinceState ||
          watchedVals.zipcode !== initialValues.zipcode
        );
      default:
        return false;
    }
  }, [watchedVals, editableBlock, initialValues]);

  useEffect(() => {
    if (data?.doctor) {
      reset(initialValues);
    }
  }, [reset, data?.doctor, initialValues]);

  useEffect(() => {
    switch (editableBlock) {
      case BlockNames.legal:
        setCurrentSchema(legalNameSchema);
        break;
      case BlockNames.email:
        setCurrentSchema(emailSchema);
        break;
      case BlockNames.address:
        setCurrentSchema(addressSchema);
        break;
      default:
        setCurrentSchema(yup.object().shape({}));
    }
  }, [editableBlock]);

  const onSubmit = async (formData: IUserProps) => {
    const {
      description,
      provinceState,
      streetadress1,
      streetadress2,
      phoneNumber,
      ...rest
    } = formData;
    const { firstName, lastName, email, username } = data?.doctor || {};

    const _editData = {
      // ...data?.doctor,
      ...rest,
      state: provinceState ?? data?.doctor?.state,
      about: description
        ? convertToRawAndStringify(description)
        : data?.doctor?.about,
      suite: streetadress2 ?? data?.doctor?.suite,
      address: streetadress1 ?? data?.doctor?.address,
      phone: phoneNumber ?? data?.doctor?.phone,
      firstName,
      lastName,
      email: editableBlock !== BlockNames.email ? email : formData.email,
      username,
    };

    // extract unused data
    // const {
    //   __typename,
    //   _id,
    //   certifications,
    //   experience,
    //   focusArea,
    //   languages,
    //   noUsedFeatures,
    //   patientGroup,
    //   picture,
    //   specialty,
    //   username,
    //   signature,
    //   ...editData
    // } = _editData;

    try {
      await editDoctor({
        variables: {
          doctorID: doctor._id,
          DoctorInput: _editData,
        },
      });

      triggerSnack();
      setEditableBlock(null);
      setErrorEmail(null);
    } catch (error) {
      console.error(error);
      setErrorEmail(error.message);
    }
  };

  const commonInputProps = {
    inputRef: register,
    variant: 'filled',
    control,
  };

  const defaultValuesCountry = {
    label: data?.doctor?.country,
  };

  return (
    <DataLoader loading={loading} error={error} data={data?.doctor}>
      <Box className="cyTestPerconalInfo">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
              <Skeleton loading={loading}>
                <Text i18nKey="Personal info" className={classes.h3}>
                  Contact information
                </Text>
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
              xl={4}
              lg={5}
              md={6}
              sm={12}
              style={{ marginLeft: '2rem' }}
            >
              <InfoBlock
                title={t('Legal name')}
                description={t(
                  'This is the name as it appears on your official documents'
                )}
                content={`${data?.doctor?.firstname} ${data?.doctor?.lastname}`}
                blockName={BlockNames.legal}
                isEditable={editableBlock === BlockNames.legal}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={loading}
                disabledButton={!hasChanged}
                buttonLoading={loadingMutation}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                    <InputBox
                      labelName={t('First name')}
                      type="text"
                      name="firstname"
                      datacy="firstname-input"
                      errors={errors}
                      loading={loading}
                      {...commonInputProps}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                    <InputBox
                      labelName={t('Last name')}
                      type="text"
                      name="lastname"
                      datacy="lastname-input"
                      errors={errors}
                      loading={loading}
                      {...commonInputProps}
                    />
                  </Grid>
                </Grid>
              </InfoBlock>
              <InfoBlock
                title={t('Email address')}
                content={data?.doctor?.email}
                blockName={BlockNames.email}
                isEditable={editableBlock === BlockNames.email}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={loading}
                disabledButton={!hasChanged}
                buttonLoading={loadingMutation}
                setError={setErrorEmail}
              >
                <InputBox
                  labelName={t('Email')}
                  className={classes.marginTop2}
                  type="text"
                  name="email"
                  datacy="email-input"
                  error={errorEmail}
                  errors={errors}
                  loading={loading}
                  {...commonInputProps}
                />
              </InfoBlock>
              <InfoBlock
                title={t('Phone number')}
                content={data?.doctor?.phone}
                blockName={BlockNames.phone}
                isEditable={editableBlock === BlockNames.phone}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={loading}
                disabledButton={!hasChanged}
                buttonLoading={loadingMutation}
              >
                <Input
                  type={'phone'}
                  name="phoneNumber"
                  data-cy="phone-input"
                  {...commonInputProps}
                  helperText={
                    errors.phoneNumber && (
                      <FormHelperText className={classes.error}>
                        <ErrorIcon className={classes.errorIcon} />
                        {errors?.phoneNumber?.message}
                      </FormHelperText>
                    )
                  }
                />
              </InfoBlock>
              <InfoBlock
                title={t('Address')}
                content={
                  !data?.doctor?.address && !data?.doctor?.suite
                    ? '-'
                    : `${data?.doctor?.address || ''} ${
                        data?.doctor?.suite || ''
                      }`
                }
                extraContent={
                  !data?.doctor?.country &&
                  !data?.doctor?.city &&
                  !data?.doctor?.state &&
                  !data?.doctor?.zipcode
                    ? '-'
                    : `${data?.doctor?.country || ''} ${
                        data?.doctor?.city || ''
                      }, ${data?.doctor?.state || ''}, ${
                        data?.doctor?.zipcode || ''
                      }`
                }
                description={t(
                  'Use a permanent address where you can receive mail.'
                )}
                blockName={BlockNames.address}
                isEditable={editableBlock === BlockNames.address}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={loading}
                disabledButton={!hasChanged}
                buttonLoading={loadingMutation}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                    <Skeleton loading={loading}>
                      <InputLabel className={clsx(classes.label)}>
                        <Text i18nKey="country">Country</Text>
                      </InputLabel>
                    </Skeleton>
                    <Skeleton loading={loading}>
                      <Input
                        className={clsx(classes.input, classes.marginTop)}
                        type="country"
                        name="country"
                        data-cy="country-input"
                        {...commonInputProps}
                        defaultValue={defaultValuesCountry}
                        required
                      />
                    </Skeleton>
                    <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                      <Skeleton loading={loading}>
                        <InputLabel
                          className={clsx(classes.label, classes.marginTop2)}
                        >
                          <Text i18nKey="Street_Address_1">
                            Street address 1
                          </Text>
                        </InputLabel>
                      </Skeleton>
                      <Skeleton loading={loading}>
                        <Input
                          className={clsx(classes.longInput, classes.marginTop)}
                          type="text"
                          name="streetadress1"
                          {...commonInputProps}
                        />
                      </Skeleton>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                      <Skeleton loading={loading}>
                        <InputLabel
                          className={clsx(classes.label, classes.marginTop2)}
                        >
                          <Text i18nKey="Street_Address_2">
                            Street address 2
                          </Text>
                        </InputLabel>
                      </Skeleton>
                      <Skeleton loading={loading}>
                        <Input
                          className={clsx(classes.longInput, classes.marginTop)}
                          type="text"
                          name="streetadress2"
                          {...commonInputProps}
                        />
                      </Skeleton>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                        <InputBox
                          labelName={t('City')}
                          className={classes.marginTop2}
                          type="text"
                          name="city"
                          datacy="city-input"
                          defaultValue={data?.doctor?.city}
                          errors={errors}
                          loading={loading}
                          {...commonInputProps}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                        <InputBox
                          labelName={t('State/Province')}
                          className={classes.marginTop2}
                          type="text"
                          name="provinceState"
                          datacy="provinceState-input"
                          errors={errors}
                          loading={loading}
                          {...commonInputProps}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                      <InputBox
                        labelName={t('Zip code')}
                        className={classes.marginTop2}
                        type="number"
                        name="zipcode"
                        datacy="zipcode-input"
                        errors={errors}
                        loading={loading}
                        {...commonInputProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </InfoBlock>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DataLoader>
  );
};
export default PersonnalInformation;
