import { Box, Typography, makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import { FormEmail } from 'pages/PatientActivitiesPage/CreateEditForm/FormEmail';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useEditorContext } from '../EditorContextProvider';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { GET_DOCTOR } from 'pages/DoctorProfilePage/graphql';
import { useQuery } from '@apollo/client';
import { ReactComponent as PatientSignature } from 'assets/PatientSignature.svg';
import ProgressLoader from 'components/ui/ProgressLoader';
import { useHistory } from 'react-router-dom';
import PracticeInformation from 'pages/AdminPage/TabsContent/PracticeInformation';
import BasicModal from 'components/ui/Modal';

const useStyles = makeStyles((theme) => ({
  signText: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#0054B6',
    textDecoration: 'underline',
  },
  signDesc: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#0054B6',
  },
}));
const SignatureResponse = ({ data, updateResponse }: any) => {
  const classes = useStyles();
  const { open, toggleSignature } = useEditorContext();
  const { doctor } = useAuth();
  const { data: dataAignature, loading: loadingQuery } = useQuery(GET_DOCTOR, {
    variables: { DoctorId: doctor?._id },
  });
  const [openSignatureModal, setOpenSignatureModal] = React.useState(false);
  const history = useHistory();
  function handleCloseSignaturemodal() {
    setOpenSignatureModal(false);
  }
  return (
    <Box>
      <Box
        display="flex"
        justifyContent={open ? 'flex-start' : 'center'}
        alignItems={open ? 'flex-start' : 'center'}
        flexDirection="column"
        style={{
          backgroundColor: '#F0F9FF',
          cursor: 'pointer',
          borderRadius: '16px',
        }}
        py={'16px'}
        onClick={() => {
          if (!dataAignature?.doctor?.signature) {
            setOpenSignatureModal(true);
          } else {
            toggleSignature(true);
          }
        }}
      >
        {!open && (
          <>
            <PatientSignature style={{ marginBottom: '10px' }} />
            <Typography className={classes.signText}>Sign here</Typography>
            <Box mt={'10px'} mb={'10px'}>
              <Typography className={classes.signDesc}>
                By Inserting your signature
              </Typography>
              <Typography className={classes.signDesc}>
                you are e-signing this form
              </Typography>
            </Box>
          </>
        )}

        <Box
          display={!open ? 'none' : 'flex'}
          justifyContent="space-between"
          flexDirection="row-reverse"
          width="100%"
        >
          <Box
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              history.push('/doctor/admin?tab=5');
            }}
            pr={2}
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: '16px',
                color: '#0054B6',
                textDecoration: 'underline',
              }}
            >
              Edit
            </Typography>
          </Box>
          <Box px={2}>
            <img
              src={dataAignature?.doctor?.signature?.picture}
              style={{
                width: '100%',
                height: '58px',
              }}
            />
            <Box
              display={'flex'}
              flexDirection={'column'}
              mb={'10px'}
              mt={'10px'}
            >
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '17.74px',
                  color: '#0054B6',
                }}
              >
                Signed by {doctor?.firstname} {doctor?.lastname}
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  lineHeight: '17.74px',
                  fontSize: '14px',
                  color: '#0054B6',
                }}
              >
                {dayjs(dataAignature?.doctor?.signature?.updatedAt).format(
                  'MMM D, YYYY h:mm A'
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <BasicModal
        open={openSignatureModal}
        onClose={handleCloseSignaturemodal}
        handleClose={handleCloseSignaturemodal}
        isSlide
      >
        <Box
          sx={{
            width: {
              xs: '50vw',
              lg: '25vw',
              xl: '25vw',
            },
            height: '100vh',
          }}
          style={{ backgroundColor: 'white' }}
          p={4}
        >
          <PracticeInformation
            isEditable
            handleCloseSignaturemodal={handleCloseSignaturemodal}
            isPracticeSetting={false}
          />
        </Box>
      </BasicModal>
    </Box>
  );
};

export default SignatureResponse;
