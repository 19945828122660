import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import './style.css';
import UploadIcon from 'assets/form/blocks/UploadIcon';

interface ImageData {
  imageUrl?: string;
  imageFile?: string;
}

interface ImageConfig {
  edit?: boolean;
}

interface ImageConstructorProps {
  data: ImageData;
  config?: ImageConfig;
  api: any;
  readOnly: boolean;
}

export default class Image {
  private api: any;
  private readOnly: boolean;
  private data: ImageData;
  private CSS: { wrapper: string; dropZone: string; image: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;
  private fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
  private root: Root | null = null;

  static get toolbox() {
    return {
      icon: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 16V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16ZM5.5 10.5L8 13.51L11.5 9L16 15H2L5.5 10.5Z" fill="#475467"/>
        </svg>`,
      title: 'Image',
    };
  }

  constructor({ data, config, api, readOnly }: ImageConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config?.edit ?? true;
    this.data = data || {};
    this.CSS = {
      wrapper: 'image-tool',
      dropZone: 'image-tool__drop-zone',
      image: 'image-tool__image',
    };
    this.nodes = { holder: null };
  }

  private uploadImage = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const base64Data = e.target?.result as string;

      const newObject = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModified,
        size: file.size,
        filename: file.name,
        mimetype: file.type,
        base64Data,
      };

      this.data = {
        ...this.data,
        imageUrl: base64Data,
        imageFile: JSON.stringify(newObject), // Store metadata as JSON
      };

      this.renderContents();
    };

    reader.readAsDataURL(file);
  };

  private handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('File size exceeds 20MB limit.');
        return;
      }
      this.uploadImage(file);
    }
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.classList.add('image-tool');
    this.nodes.holder = rootNode;
    if (!this.root && this.nodes.holder) {
      this.root = createRoot(this.nodes.holder);
    }

    this.renderContents();
    return this.nodes.holder;
  }

  private renderContents() {
    if (!this.root) return;

    const content =
      this.data.imageUrl || this.data.imageFile ? (
        <img
          src={this.data.imageUrl || this.data.imageFile}
          className="image"
          alt="Uploaded"
        />
      ) : (
        <div
          className="wrapper"
          onMouseDown={(event) => {
            event.preventDefault();
            if (this.fileInputRef.current?.showPicker) {
              this.fileInputRef.current.showPicker();
            } else {
              this.fileInputRef.current?.click();
              this.fileInputRef.current?.focus();
            }
          }}
          onDrop={(event) => {
            event.preventDefault();
            const file = event.dataTransfer?.files[0];
            if (file) this.uploadImage(file);
          }}
          onDragOver={(event) => event.preventDefault()}
        >
          <div className="container">
            <div className="image-upload">
              <UploadIcon />
            </div>
            <p className="upload-text">
              <span className="click-text">Click to upload</span> or drag and
              drop the image
            </p>
            <p className="image-tool__file-size-info">(Max. 20MB)</p>
            <input
              type="file"
              ref={this.fileInputRef}
              className="hidden-input"
              onChange={this.handleFileSelect}
              accept="image/jpeg, image/png, image/bmp"
            />
          </div>
        </div>
      );

    this.root.render(content);
  }

  save(): ImageData {
    return this.data;
  }
  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }
}
